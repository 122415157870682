import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/angular-ivy';
import { environment } from 'src/environments/environment';
import { SessionService } from 'src/core/session/domain/session.service';
import { ApiException } from 'src/core/common/domain/exceptions/api-exception';
import { ClientException } from 'src/core/common/domain/exceptions/client-exception';
import { HttpErrorResponse } from '@angular/common/http';

type SentryTags = {
  rs_error_code: string;
  rs_user_id?: string;
  rs_user_username?: string;
};

@Injectable({ providedIn: 'any' })
export class SentryIonicErrorLogger {
  constructor(private readonly session: SessionService) {}

  async log(error: Error | ClientException | ApiException, code?: string) {
    if (!environment.is_dist) {
      console.log(
        `Environment: ${environment.name}. Not sending error to Sentry because it is not a "dist" environment`
      );
      return;
    }

    console.log(`Environment: ${environment.name}. Sending error to Sentry`);
    try {
      const message = this.buildMessage(error, code);
      const user = this.session.getUser();
      const tags: SentryTags = {
        rs_error_code: this.getErrorCode(error, code),
      };
      if (user !== null) {
        Sentry.setUser({
          id: user.id,
          username: user.username,
        });
        tags.rs_user_id = user.id;
        tags.rs_user_username = user.username;
      }
      let originalError = error;
      if (error instanceof ApiException) {
        originalError = error.originalError;
      }
      Sentry.captureException(error, {
        tags,
        extra: {
          message,
          original_error: originalError,
        },
      });
    } catch (e) {
      console.error('error while sending to Sentry:')
      console.error(e);
    }
  }

  private getErrorCode(error: Error | ClientException | ApiException, code: string | undefined) {
    if (error instanceof ApiException) {
      return error.code;
    }

    return code;
  }

  private buildMessage(error: Error | ClientException | ApiException, code: string | undefined): string {
    const message = error.message;
    if (error instanceof ApiException) {
      const status = (error.originalError as HttpErrorResponse)?.status;
      return `API uncontrolled error (status: ${status}, code: ${error.code}, message: ${message})`;
    }

    if (error instanceof ClientException) {
      return `Client controlled error, code ${code}: ${message}`;
    }

    return `Client uncontrolled error, code ${code}: ${message}`;
  }
}
