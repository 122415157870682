import { StringValueObject } from 'src/core/common/domain/value-objects/string-value-object';
import { LocalDate } from '../../common/domain/date/local-date';
import { Nullable } from '../../common/domain/types/types';

type AccessTokenPayload = {
  exp: number;
};

export class AccessToken extends StringValueObject {
  static from(value: Nullable<string>) {
    if (!value) {
      return null;
    }

    return new this(value);
  }

  isExpired() {
    const decodedToken = this.decodeToken();
    if (!decodedToken) {
      return true;
    }

    // we multiply by 1000 because Date works with milliseconds and the api returns seconds
    const expirationDate = LocalDate.fromTimestamp(decodedToken.exp * 1000).timestamp;
    const now = LocalDate.now().timestamp;
    const secondsToExpire = Number(expirationDate) - Number(now);

    return secondsToExpire <= 0;
  }

  private decodeToken(): Nullable<AccessTokenPayload> {
    try {
      return JSON.parse(window.atob(this.value.split('.')[1]));
    } catch (e) {
      return null;
    }
  }
}
