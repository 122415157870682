import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';
import { Attachment } from 'src/core/attachment/domain/attachment';
import { UuidGenerator } from 'src/core/common/infrastructure/uuid/uuid-generator';
import { Injectable } from '@angular/core';
import { Nullable } from 'src/core/common/domain/types/types';
import { LocalFileAttachment } from 'src/core/attachment/domain/local-file-attachment';
import { Task } from 'src/core/master-data/domain/task';
import { FilesystemService } from 'src/core/filesystem/domain/filesystem.service';

@Injectable({
  providedIn: 'any',
})
export class CameraService {
  constructor(private camera: Camera, private filesystem: FilesystemService) {}

  async pickPhotoFromGallery(task: Task): Promise<Nullable<Attachment>> {
    const cameraOptions: CameraOptions = {
      ...this.getBaseCameraOptions(),
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      // allowEdit is unpredictable on Android, and it should not be used! (https://cordova.apache.org/docs/en/latest/reference/cordova-plugin-camera/index.html)
      allowEdit: false,
    };

    try {
      const imageURL = await this.camera.getPicture(cameraOptions);
      return await this.buildAttachment(task, imageURL);
    } catch (error) {
      if (error === 'No Image Selected') {
        return null;
      }
      throw error;
    }
  }

  async takePhotoFromCamera(task: Task): Promise<Nullable<Attachment>> {
    const cameraOptions: CameraOptions = {
      ...this.getBaseCameraOptions(),
      saveToPhotoAlbum: true,
    };

    try {
      const imageURL = await this.camera.getPicture(cameraOptions);
      return await this.buildAttachment(task, imageURL);
    } catch (error) {
      if (error === 'No Image Selected') {
        return null;
      }
      throw error;
    }
  }

  private async buildAttachment(task: Task, imageURL: string): Promise<Nullable<Attachment>> {
    try {
      // copy file from plugin temporary folder to APP data directory (to avoid OS to delete temporary folders and lose images)
      const fileEntry = await this.filesystem.copyFileToFolder(imageURL, task.getAttachmentsDirectory());
      const size = await this.filesystem.getFileSize(fileEntry.nativeURL);
      return new LocalFileAttachment(UuidGenerator.random(), fileEntry.name, fileEntry.nativeURL, size);
    } catch (error) {
      console.log(error);
    }

    return null;
  }

  private getBaseCameraOptions(): CameraOptions {
    return {
      quality: 100,
      targetWidth: 1024,
      targetHeight: 1024,
      correctOrientation: true,
      mediaType: this.camera.MediaType.PICTURE,
      encodingType: this.camera.EncodingType.JPEG,
      destinationType: this.camera.DestinationType.FILE_URI,
    };
  }
}
