import { Attachment, AttachmentPrimitives } from './attachment';

export class LocalFileAttachment extends Attachment {
  constructor(id: string, filename: string, public localPath: string, size: number) {
    super(id, filename, size);
  }

  isLocal(): boolean {
    return true;
  }

  getImageSrc(): string {
    // this only works for Android at the moment. If iOS is needed, another solution must be implemented
    return (window as any).Ionic.WebView.convertFileSrc(this.localPath);
  }

  getUrl(): string {
    return this.localPath;
  }

  toPrimitives(): AttachmentPrimitives {
    return {
      id: this.id,
      filename: this.filename,
      size: this.size,
      path: this.localPath,
    };
  }
}
