import { LocalDate } from '../../common/domain/date/local-date';

export class DateRange {
  constructor(public readonly from: LocalDate, public readonly to: LocalDate) {}

  static fromPrimitives(primitives: { from: string; to: string }) {
    return new DateRange(LocalDate.fromString(primitives.from), LocalDate.fromString(primitives.to));
  }

  isInRange(date: LocalDate): boolean {
    return date.atom >= this.from.atom && date.atom <= this.to.atom;
  }
}
