import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Injectable } from '@angular/core';
import { PlatformService } from 'src/core/common/domain/platform/platform.service';
import { NetworkService } from '../domain/network.service';

@Injectable({
  providedIn: 'any',
})
export class CordovaNetworkService extends NetworkService {
  private pollingInterval = 3000;
  private isPollingActive = false;

  constructor(private readonly network: Network, private platform: PlatformService) {
    super();

    if (!this.platform.isCordova()) {
      return;
    }
    this.addEventListeners();
  }

  setInitialValue(): void {
    this.setNetworkOnline();
  }

  private addEventListeners() {
    // Since iNetwork library does not work properly for iOS, we bypass it as always "connected"
    if (this.platform.isIos()) {
      this.setNetworkOnline();
      return;
    }

    this.network.onChange().subscribe((status) => {
      if (status === 'connected') {
        this.setNetworkOnline();
      } else {
        this.setNetworkOffline();
        this.startPollingForConnection();
      }
    });
  }

  private startPollingForConnection() {
    if (this.isPollingActive) {
      return;
    }

    this.isPollingActive = true;
    const intervalId = setInterval(() => {
      if (this.network.type !== 'none') {
        this.setNetworkOnline();
        clearInterval(intervalId); // Detiene el intervalo cuando la conexión está de vuelta
        this.isPollingActive = false; // Marca que el intervalo ha terminado
      }
    }, this.pollingInterval);
  }
}
