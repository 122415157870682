import { TaskDate } from 'src/core/master-data/domain/task-date';
import { LocalDate } from './local-date';
import * as moment from 'moment-timezone';

export class DateHelper {
  static expectedDate(date: TaskDate): string {
    const expected = date.expected;
    const from = date.range.from;
    const to = date.range.to;
    const rangeInSameDay = from.date.isSame(to.date, 'day');
    const completeDays =
      from.toFormat('HH:mm:ss') === '00:00:00' &&
      (to.toFormat('HH:mm:ss') === '00:00:00' || to.toFormat('HH:mm:ss') === '23:59:59');

    if (rangeInSameDay) {
      return completeDays
        ? `${expected.toFormat('DD/MM')}`
        : `${expected.toFormat('DD/MM')} (${from.toFormat('H:mm')} - ${to.toFormat('H:mm')})`;
    }

    return completeDays
      ? `${expected.toFormat('DD/MM')} (OK: ${from.toFormat('DD/MM')} - ${to.toFormat('DD/MM')})`
      : `${expected.toFormat('DD/MM')} (OK: ${from.toFormat('DD/MM H:mm')} - ${to.toFormat('DD/MM H:mm')})`;
  }

  static thisWeekRange(): { from: string; to: string } {
    moment.locale('es');
    return {
      from: LocalDate.fromIsoString(moment().startOf('week').format()).ymd,
      to: LocalDate.fromIsoString(moment().endOf('week').format()).ymd,
    };
  }

  static thisMonthRange(): { from: string; to: string } {
    moment.locale('es');
    return {
      from: LocalDate.fromIsoString(moment().startOf('month').format()).ymd,
      to: LocalDate.fromIsoString(moment().endOf('month').format()).ymd,
    };
  }

  static lastMonthRange(): { from: string; to: string } {
    moment.locale('es');
    return {
      from: LocalDate.fromIsoString(moment().subtract(1, 'month').startOf('month').format()).ymd,
      to: LocalDate.fromIsoString(moment().subtract(1, 'month').endOf('month').format()).ymd,
    };
  }
}
