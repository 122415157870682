export enum AttachmentType {
  IMAGE = 'image',
  UNKNOWN = 'unknown',
}

export type AttachmentPrimitives = { id: string; filename: string; path: string; size: number };

export abstract class Attachment {
  public type: AttachmentType;

  protected constructor(public id: string, public filename: string, public size: number = 0) {
    this.calculateType();
  }

  abstract getImageSrc(): string;

  abstract getUrl(): string;

  abstract toPrimitives(): AttachmentPrimitives;

  isRemote(): boolean {
    return false;
  }

  isLocal(): boolean {
    return false;
  }

  getBackgroundImage(): string {
    return 'url(' + this.getImageSrc() + ')';
  }

  isLargerThen(size: number): boolean {
    console.log(`${this.size} > ${size}`);
    return this.size > size;
  }

  private calculateType() {
    const extension = this.getFilenameExtension();
    this.type = this.getTypeFromExtension(extension);
  }

  private getTypeFromExtension(extension: string) {
    switch (extension) {
      case 'png':
      case 'jpg':
      case 'jpeg':
      case 'bmp':
      case 'gif':
      case 'webp':
        return AttachmentType.IMAGE;
    }

    return AttachmentType.UNKNOWN;
  }

  private getFilenameExtension(): string {
    return this.filename.split('.').pop().toLowerCase();
  }
}
