import { Injectable } from '@angular/core';
import { catchError, interval, map, Observable, Subject, Subscription, switchMap, timeout, TimeoutError } from 'rxjs';
import { PlatformService } from 'src/core/common/domain/platform/platform.service';
import { CordovaNetworkService } from '../infrastructure/cordova-network.service';
import { BrowserNetworkService } from '../infrastructure/browser-network.service';
import { HttpClient } from '@angular/common/http';
import { of } from 'rxjs/internal/observable/of';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ConnectivityService {
  private status: Subject<boolean> = new Subject();
  // eslint-disable-next-line @typescript-eslint/member-ordering
  status$: Observable<boolean> = this.status.asObservable();
  private hasConnection = false;
  private hasNetworkConnection = false;
  private hasPingConnection = false;
  private PING_URL = 'https://www.rideosoftware.com/status';
  private PING_INTERVAL = 8000;
  private PING_TIMEOUT = 3000;
  private monitoring$: Subscription;

  constructor(
    private platform: Platform,
    private platformService: PlatformService,
    private http: HttpClient,
    private cordovaNetwork: CordovaNetworkService,
    private browserNetwork: BrowserNetworkService
  ) {
    this.bindEvents();
    this.startMonitoringConnectivity();
    this.getNetworkService().setInitialValue();
  }

  toggle() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.hasConnection ? this.disableConnection() : this.enableConnection();
  }

  isOnline(): boolean {
    return this.hasConnection;
  }

  private getNetworkService() {
    return this.platformService.isCordova() ? this.cordovaNetwork : this.browserNetwork;
  }

  private bindEvents() {
    this.getNetworkService().connected$.subscribe((hasConnection) => {
      if (hasConnection) {
        this.hasNetworkConnection = true;
        this.enableConnection();
      } else {
        this.hasNetworkConnection = false;
        this.disableConnection();
      }
    });

    this.platform.pause.subscribe(async () => {
      console.warn('Received app:pause event, stopping connectivity monitoring');
      this.stopMonitoringConnectivity();
    });

    this.platform.resume.subscribe(async () => {
      console.warn('Received app:resume event, initializing connectivity monitoring');
      this.startMonitoringConnectivity();
    });
  }

  private startMonitoringConnectivity(): void {
    // DEBUG: Disabled atm
    const enabled = false;
    if (!enabled) {
      return;
    }

    this.stopMonitoringConnectivity();
    this.monitoring$ = interval(this.PING_INTERVAL)
      .pipe(switchMap(() => this.pingServer()))
      .subscribe((hasConnection: boolean) => {
        if (hasConnection === this.hasPingConnection) {
          return;
        }
        if (hasConnection) {
          this.hasPingConnection = true;
          this.enableConnection();
        } else {
          this.hasPingConnection = false;
          this.disableConnection();
        }
      });
  }

  private stopMonitoringConnectivity(): void {
    if (this.monitoring$) {
      this.monitoring$.unsubscribe();
    }
  }

  private pingServer(): Observable<boolean> {
    return this.http.get(this.PING_URL).pipe(
      timeout(this.PING_TIMEOUT),
      map(() => true),
      catchError((error) => {
        // if captures an error, only return false if the error is from Timeout (no connection)
        // if it is for any other reason, we do not turn off the connection (to avoid turning off the connection
        // of everybody in the case of any problem in our server)
        const isTimeout = error instanceof TimeoutError;
        return of(!isTimeout);
      })
    );
  }

  private enableConnection(): void {
    // if we have ping connection but no network connection we do not enable the connection
    // because network on/off is immediate, but ping connection can have some delay
    if (this.hasPingConnection && !this.hasNetworkConnection) {
      return;
    }

    this.hasConnection = true;
    this.status.next(true);
  }

  private disableConnection(): void {
    this.hasConnection = false;
    this.status.next(false);
  }
}
