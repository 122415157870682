import { Injectable } from '@angular/core';
import * as moment from 'moment-timezone';
import { AppService } from '../../../app/app.service';
import { SessionService } from '../../session/domain/session.service';
import { LoadMasterData } from '../../master-data/application/load/load-master-data';
import { FiltersService } from '../../filters/domain/filters-service';
import { LoadingHelper } from '../../../app/helpers/loading-helper';
import { Translation } from '../../common/domain/translation/translation';
import { TimeoutException } from "../../common/domain/exceptions/timeout-exception";
import { NoConnectionException } from "../../common/domain/exceptions/no-connection-exception";

@Injectable({
  providedIn: 'root',
})
export class DataService {
  private static readonly THRESHOLD_IN_MINUTES = 12 * 60; // 12 hours
  // private static readonly THRESHOLD_IN_MINUTES = 1; // 1 minute, for testing

  constructor(
    private readonly appService: AppService,
    private readonly filtersService: FiltersService,
    private readonly loadingHelper: LoadingHelper,
    private readonly loadMasterData: LoadMasterData,
    private readonly translation: Translation,
    private readonly session: SessionService
  ) {}

  async checkCurrentData() {
    const isAuthenticated = await this.session.isAuthenticatedPromise();
    if (!isAuthenticated) {
      return;
    }

    // const isDataOutdated = true; // Uncomment to force data is outdated
    const isDataOutdated = await this.isDataOutdated();
    if (!isDataOutdated) {
      console.log('Current APP data (master data, filters) is still valid');
      return;
    }

    await this.downloadMasterData(); // throws NoConnection
    await this.resetFiltersToDefault();
  }

  private async downloadMasterData() {
    await this.loadingHelper.start(this.translation.instant('MESSAGES.DOWNLOADING_MASTER_DATA'));
    try {
      await this.loadMasterData.execute();
    } catch (exception) {
      if (this.isConnectionProblem(exception)) {
        // don't show any toast since here it is not needed
      }
    }
    await this.loadingHelper.end();
  }

  private isConnectionProblem(exception: any): boolean {
    return exception instanceof TimeoutException || exception instanceof NoConnectionException;
  }

  private async resetFiltersToDefault() {
    await this.filtersService.resetToDefault();
  }

  private async isDataOutdated(): Promise<boolean> {
    // Data must be reset if last put-to-background time was yesterday, more than 12 hours ago, and user is still logged in.
    const isAuthenticated = await this.session.isAuthenticatedPromise();
    if (!isAuthenticated) {
      return false;
    }

    const startPauseTime = await this.appService.getAppPutToBackgroundTime();
    const startedAt = startPauseTime ? startPauseTime.date : moment();
    // const startedAt = moment().subtract(721, 'minutes'); // 12h 01m (fake data to force outdated behaviour)
    const now = moment();
    const diffInMinutes = now.diff(startedAt, 'minutes');
    const sameDay = startedAt.isSame(now, 'day');
    const currentDataIsTooOld = diffInMinutes > DataService.THRESHOLD_IN_MINUTES;
    const dataIsStillValid = !currentDataIsTooOld && sameDay;
    console.warn(
      `Current stored data (master data, filters) is ${diffInMinutes} seconds old. It is ${
        dataIsStillValid ? 'still valid' : 'outdated'
      }`
    );
    return !dataIsStillValid;
  }
}
