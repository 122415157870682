import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Nullable } from 'src/core/common/domain/types/types';
import { EventsService } from 'src/core/events/events.service';
import { SessionService } from 'src/core/session/domain/session.service';
import { Storage } from 'src/core/storage/domain/storage';
import { MasterDataService } from '../core/master-data/domain/master-data-service';
import { FiltersService } from '../core/filters/domain/filters-service';
import { Logout } from '../core/authentication/application/logout/logout';
import { PlatformService } from '../core/common/domain/platform/platform.service';
import { AppService } from './app.service';
import { register } from 'swiper/element/bundle';
import { ApiService } from '../core/api/domain/api.service';
import { MessageHelper } from './helpers/message-helper';
import { SystemService } from '../core/system/system.service';
import { TaskService } from 'src/core/tasks/domain/task.service';
import { DataService } from '../core/data/domain/data-service';
import { NFCResponse, NFCService } from '../core/nfc/domain/nfc.service';
import { NFCMessageParser } from '../core/nfc/domain/nfc-message-parser';
import { CredentialsScannedEvent } from '../core/events/credentials-scanned.event';
import { UserLoggedOutEvent } from 'src/core/events/user-logged-out.event';
import { QueueService } from '../core/queue/domain/queue.service';
import { ConnectivityService } from '../core/connection/domain/connectivity.service';
import { MandatoryUpdateModalPage } from './components/mandatory-update-modal/mandatory-update-modal';
import { Translation } from '../core/common/domain/translation/translation';
import * as moment from 'moment-timezone';

// we need to call Swiper's register function to globally register Swiper's custom elements.
register();

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  private appIsOutdated$: Nullable<Subscription> = null;
  private subscriptionUserLoggedOut$: Nullable<Subscription> = null;
  private subscriptionAuthorizationExpired$: Nullable<Subscription> = null;
  private nfcSubscription: Subscription;
  private subscriptionCredentialsScanned$: Subscription;
  private newAppVersionAvailable$: Subscription;

  constructor(
    private readonly apiService: ApiService,
    private readonly appService: AppService,
    private readonly connectivityService: ConnectivityService,
    private readonly dataService: DataService,
    private readonly events: EventsService,
    private readonly modalCtrl: ModalController,
    private readonly filtersService: FiltersService,
    private readonly firebaseX: FirebaseX,
    private readonly logout: Logout,
    private readonly masterDataService: MasterDataService,
    private readonly messageHelper: MessageHelper,
    private readonly nfcService: NFCService,
    private readonly platform: Platform,
    private readonly platformService: PlatformService,
    private readonly queueService: QueueService,
    private readonly router: Router,
    private readonly session: SessionService,
    private readonly statusBar: StatusBar,
    private readonly storage: Storage,
    private readonly systemService: SystemService,
    private readonly translation: Translation,
    private readonly taskService: TaskService
  ) {
    this.initializeApp().then().catch();
  }

  private async initializeApp() {
    await this.platform.ready();
    this.addBackHardwareButtonToExitApp();
    this.printCurrentPlatformInfo();
    this.addEventListeners();
    await this.configureCordova();
    await this.storage.ready();
    await this.resetAllStorageIfNeeded();

    // all services that implements initializable interface should be initialized
    await this.apiService.init();
    await this.session.init();
    await this.masterDataService.init();
    await this.taskService.init();
    await this.filtersService.init();
    await this.queueService.init();

    await this.systemService.storeCurrentVersionToStorage();
    await this.dataService.checkCurrentData();
  }

  private addBackHardwareButtonToExitApp() {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (!this.platform.is('cordova')) {
        return;
      }

      const currentUrl = this.router.url;
      const rootPaths = ['/login', '/tabs/tasks', '/tabs/master-data', '/tabs/synchronization', '/tabs/more'];
      if (rootPaths.indexOf(currentUrl) === -1) {
        return;
      }

      // eslint-disable-next-line
      if (!navigator['app']) {
        return;
      }

      // eslint-disable-next-line
      navigator['app'].exitApp();
    });
  }

  private async resetAllStorageIfNeeded() {
    const needsToBeReset = await this.systemService.storageNeedsToBeReset();
    if (!needsToBeReset) {
      return;
    }

    await this.doLogout();
    await this.systemService.resetStorage();
    window.location.reload();
  }

  private printCurrentPlatformInfo(): void {
    // eslint-disable-next-line no-console
    console.info(`%c Platform: ${this.platform.platforms().join(', ')}`, 'background: #222; color: #bada55');
  }

  private addEventListeners(): void {
    this.subscribeToPauseResumeEvents();
    this.subscribeToAppIsOutdatedEvent();
    this.subscribeToNewAppVersionIsAvailableEvent();
    this.subscriptionUserLoggedOut$ = this.events.userLoggedOut$.subscribe(async () => {
      await this.doLogout();
    });
    this.subscriptionAuthorizationExpired$ = this.events.authorizationExpired$.subscribe(async () => {
      await this.messageHelper.showWarning(
        'La sesión ha caducado (en la versión final de la APP se renovará automáticamente).'
      );
      await this.doLogout();
    });
    // NFC read
    this.nfcService.ready$.subscribe((ready) => {
      if (ready) {
        this.unsubscribeFromNFC();
        this.nfcSubscription = this.nfcService.listen().subscribe({
          next: async (response) => await this.processNFCData(response),
          error: async (error) => await this.messageHelper.showError(JSON.stringify(error)),
        });
      } else {
        this.unsubscribeFromNFC();
      }
    });
    this.subscriptionCredentialsScanned$ = this.events.credentialsScanned$.subscribe(async (event) => {
      const isAuthenticated = await this.session.isAuthenticatedPromise();
      if (!isAuthenticated) {
        // alert('Scanned while NOT authenticated, skipping since login would take care of the event');
        return;
      }
      // alert('Scanned while authenticated, logout + login with: ' + event.username + '::' + event.password);
      if (!this.queueService.isEmpty()) {
        const ok = await this.messageHelper.askConfirmationAboutLosingPendingChanges();
        if (!ok) {
          return;
        }
      }

      this.events.publishLoggedOutEvent(new UserLoggedOutEvent());
      setTimeout(() => {
        this.events.publishCredentialsScannedEvent(event);
      }, 500);
    });

    this.connectivityService.status$.subscribe(async (hasConnection) => {
      if (hasConnection) {
        await this.queueService.processQueue();
      }
    });
  }

  private unsubscribeFromNFC() {
    if (this.nfcSubscription) {
      this.nfcSubscription.unsubscribe();
    }
  }

  private async processNFCData(response: NFCResponse) {
    if (NFCMessageParser.isCredentialsMessage(response.data)) {
      const credentialsMessage = NFCMessageParser.processCredentialsMessage(response.data, response.serialNumber);
      this.events.publishCredentialsScannedEvent(
        new CredentialsScannedEvent(
          credentialsMessage.username,
          credentialsMessage.password,
          credentialsMessage.serialNumber
        )
      );
      return;
    }
  }

  private subscribeToAppIsOutdatedEvent() {
    this.appIsOutdated$ = this.events.appIsOutdated$.subscribe(async () => {
      const modal = await this.modalCtrl.create({
        component: MandatoryUpdateModalPage,
        componentProps: {},
        backdropDismiss: false,
        cssClass: 'is-modal-on-top'
      });
      await modal.present();
    });
  }

  private subscribeToNewAppVersionIsAvailableEvent() {
    this.newAppVersionAvailable$ = this.events.newAppVersionAvailable$.subscribe(async () => {
      await this.displayNewAppVersionAvailable();
    });
  }

  private async displayNewAppVersionAvailable(): Promise<void> {
    const lastRememberTime = await this.systemService.getNewVersionAvailableLastRememberTime();
    const diffInHours = lastRememberTime ? moment().diff(moment(lastRememberTime), 'hours') : -1;
    if (diffInHours <= 4 && diffInHours !== -1) {
      return;
    }

    const message = this.translation.instant('MESSAGES.NEW_APP_VERSION_AVAILABLE');
    await this.messageHelper.showInfo(message, 3000, false);
    await this.systemService.updateNewVersionAvailableLastRememberTime();
  }

  private subscribeToPauseResumeEvents() {
    console.warn('Subscribed to app:pause event');
    this.platform.pause.subscribe(async () => {
      await this.appService.recordAppGoingToBackground();
    });

    console.warn('Subscribed to app:resume event');
    this.platform.resume.subscribe(async () => {
      await this.dataService.checkCurrentData();
    });
  }

  private async doLogout(): Promise<void> {
    await this.logout.execute();
    await this.router.navigateByUrl('/login', { replaceUrl: true });
  }

  /**
   * The platform is ready and our plugins are available.
   * Here you can do any higher level native things you might need.
   */
  private async configureCordova(): Promise<void> {
    if (!this.platform.is('cordova')) {
      return;
    }

    // let status bar overlay webview
    // Solves status bar background color when opening modals with different background colors, but needs work to adjust layout
    this.statusBar.overlaysWebView(false);

    // Set the status bar style (e.g. text color).
    this.statusBar.styleLightContent();

    await this.configureAndroid();

    // Fatal native crashes are reported to Firebase via the cordova-plugin-firebasex, since Sentry plugin did not work.
    // Other errors are reported with Sentry javascript using the error handler class, since it allows better debugging.
    await this.configureFirebase();
  }

  private async configureAndroid(): Promise<void> {
    if (!this.platform.is('android')) {
      return;
    }
    // No configuration needed atm
  }

  private async configureFirebase() {
    if (!this.platformService.isRealMobileDevice()) {
      return;
    }

    try {
      // save the token server-side and use it to push notifications to this device
      const token = await this.firebaseX.getToken();
      console.log(`The token is ${token}`);
    } catch (error) {
      console.error('Error getting token', error);
    }

    // TBC: If FirebaseX didn't get a valid token, does the following code work?
    await this.firebaseX.setCrashlyticsCollectionEnabled(true);
    await this.firebaseX.setAnalyticsCollectionEnabled(true);
    this.firebaseX.onTokenRefresh().subscribe((token: string) => console.log(`Got a new token ${token}`));
    this.firebaseX.onMessageReceived().subscribe((data) => console.log(`User opened a notification ${data}`));
  }
}
