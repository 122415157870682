import { Injectable } from '@angular/core';
import { Task } from 'src/core/master-data/domain/task';
import { TaskRepository } from 'src/core/tasks/domain/task-repository';
import { TaskService } from '../../domain/task.service';
import { StopTaskRequest } from './stop-task-request';
import { TimeoutException } from '../../../common/domain/exceptions/timeout-exception';
import { NoConnectionException } from '../../../common/domain/exceptions/no-connection-exception';
import { QueueService } from '../../../queue/domain/queue.service';
import { StopTaskQueueElement } from 'src/core/queue/domain/elements/stop-task-queue-element';

@Injectable({
  providedIn: 'any',
})
export class StopTask {
  private task: Task;
  private isSavedToServer = false;
  private isStoppedToServer = false;
  private isRetry = false;

  constructor(
    private readonly taskRepository: TaskRepository,
    private readonly taskService: TaskService,
    private readonly queueService: QueueService
  ) {}

  async execute(request: StopTaskRequest): Promise<void> {
    this.task = request.task;
    this.isRetry = request.isRetry;
    this.isSavedToServer = request.isSaveDone;
    this.isStoppedToServer = request.isStopDone;

    try {
      if (!this.isRetry) {
        this.task.stop();
        await this.saveTaskIntoLocalDevice();
      }

      const taskIsInQueue = this.queueService.hasQueueElementWithTaskId(this.task.id);
      if (taskIsInQueue && !this.isRetry) {
        await this.enqueueOperation();
        return;
      }

      await this.saveTaskToExternalServer(this.task);
      await this.stopTaskToExternalServer(this.task);
    } catch (exception) {
      // exceptions during retrying do not enqueue or rollback the task
      if (this.isRetry) {
        throw exception; // propagate exception without doing anything else
      }

      if (this.isConnectionProblem(exception)) {
        await this.enqueueOperation();
        return;
      }

      // otherwise, rollback
      this.task.rollback();
      await this.saveTaskIntoLocalDevice();
      throw exception; // propagate exception
    }
  }

  private async saveTaskIntoLocalDevice() {
    await this.taskService.saveTask(this.task);
  }

  private async saveTaskToExternalServer(task: Task) {
    if (this.isSavedToServer) {
      return;
    }
    await this.taskRepository.save(task);
    this.isSavedToServer = true;
  }

  private async stopTaskToExternalServer(task: Task) {
    if (this.isStoppedToServer) {
      return;
    }
    await this.taskRepository.stop(task);
    this.isStoppedToServer = true;
  }

  private isConnectionProblem(exception: any): boolean {
    return exception instanceof TimeoutException || exception instanceof NoConnectionException;
  }

  private async enqueueOperation(
    errors: Array<{
      parameter: string;
      reason: string;
      message: string;
    }> = []
  ) {
    const operationStatus = {
      isSavedToServer: this.isSavedToServer,
      isStoppedToServer: this.isStoppedToServer,
    };
    await this.queueService.enqueue(StopTaskQueueElement.fromTask(this.task, operationStatus, errors));
  }
}
