import { Nullable } from 'src/core/common/domain/types/types';

export class StringValueObject {
  constructor(public readonly value: string) {}

  static from(value: Nullable<string>) {
    if (!value) {
      return null;
    }

    return new this(value);
  }
}
