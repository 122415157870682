import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'any',
})
export class PlatformService {
  /**
   * | Platform Name   | Description                        |
   * |-----------------|------------------------------------|
   * | android         | on a device running Android.       |
   * | capacitor       | on a device running Capacitor.     |
   * | cordova         | on a device running Cordova.       |
   * | ios             | on a device running iOS.           |
   * | ipad            | on an iPad device.                 |
   * | iphone          | on an iPhone device.               |
   * | phablet         | on a phablet device.               |
   * | tablet          | on a tablet device.                |
   * | electron        | in Electron on a desktop device.   |
   * | pwa             | as a PWA app.                      |
   * | mobile          | on a mobile device.                |
   * | mobileweb       | on a mobile device in a browser.   |
   * | desktop         | on a desktop device.               |
   * | hybrid          | is a cordova or capacitor app.     |
   */
  constructor(private platform: Platform) {
    // todo: install @ionic-native/device, then add private device: Device
  }

  isCordova(): boolean {
    return this.platform.is('cordova');
  }

  isIos() {
    return this.platform.is('ios') || this.platform.is('ipad') || this.platform.is('iphone');
  }

  /**
   * App or Web in mobile device
   */
  isRealMobileDevice(): boolean {
    if (!this.isCordova()) {
      return false;
    }

    // on desktop DEV and simulating device, is NOT a real mobile device
    if (this.platform.is('mobileweb')) {
      return false;
    }

    // To fix platform.is('ipad') not working on some devices
    // const isIpadFix = this.device.model !== null && this.device.model.includes('iPad');
    const isIpadFix = true;
    const isIpad = this.platform.is('ipad') || isIpadFix;

    return isIpad || this.platform.is('ios') || this.platform.is('android');
  }
}
