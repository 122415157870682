import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'tabs',
    loadChildren: () => import('./tabs/tabs.module').then((m) => m.TabsPageModule),
  },
  {
    path: '404',
    loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule),
  },
];

if (!environment.is_dist) {
  // routes.push({
  //   path: 'style-guide',
  //   loadChildren: () => import('./pages/style-guide/style-guide.module').then((m) => m.StyleGuidePageModule),
  // });
}

routes.push({
  path: '**',
  loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule),
});

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
