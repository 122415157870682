export class TaskStatus {
  public static readonly PENDING: string = 'pending';
  public static readonly IN_PROGRESS: string = 'in_progress';
  public static readonly CLOSED: string = 'closed';
  public static readonly REJECTED: string = 'rejected';

  private constructor(public readonly value: string) {
    this.ensureStatusIsValid(value);
  }

  static fromPrimitives(status: string): TaskStatus {
    return new this(status);
  }

  static pending(): TaskStatus {
    return new TaskStatus(TaskStatus.PENDING);
  }

  static inProgress(): TaskStatus {
    return new TaskStatus(TaskStatus.IN_PROGRESS);
  }

  static closed(): TaskStatus {
    return new TaskStatus(TaskStatus.CLOSED);
  }

  static rejected(): TaskStatus {
    return new TaskStatus(TaskStatus.REJECTED);
  }

  isPending(): boolean {
    return this.value === TaskStatus.PENDING;
  }

  isInProgress(): boolean {
    return this.value === TaskStatus.IN_PROGRESS;
  }

  isClosed(): boolean {
    return this.value === TaskStatus.CLOSED;
  }

  isRejected(): boolean {
    return this.value === TaskStatus.REJECTED;
  }

  toPrimitives(): string {
    return this.value;
  }

  private ensureStatusIsValid(status: string) {
    const validStatus = [TaskStatus.PENDING, TaskStatus.IN_PROGRESS, TaskStatus.CLOSED, TaskStatus.REJECTED];
    if (validStatus.indexOf(status) === -1) {
      throw new Error(`Status <${status}> is not valid`);
    }
  }
}
