import { Injectable } from '@angular/core';
import { FilesystemService } from 'src/core/filesystem/domain/filesystem.service';
import { LocalDate } from 'src/core/common/domain/date/local-date';

@Injectable({
  providedIn: 'root',
})
export class GarbageCollectorService {
  private directoriesToDelete: Map<string, string[]> = new Map();

  constructor(private filesystem: FilesystemService) {}

  addDirectoryToDelete(directory: string): void {
    const key = LocalDate.now().ymd;

    const directories = this.directoriesToDelete.get(key) ?? [];
    directories.push(directory);
    this.directoriesToDelete.set(key, directories);
  }

  /**
   * Decide from where it should be called and how often
   */
  async pruneDirectoriesToDelete(): Promise<void> {
    for (const [day, directories] of this.directoriesToDelete.entries()) {
      if (this.isOld(day)) {
        for (const directory of directories) {
          await this.filesystem.deleteDirectory(directory);
        }
      }
    }
  }

  private isOld(day: string) {
    const today = LocalDate.now();
    const diffInDays = today.date.diff(LocalDate.fromFormat(day, 'YYYY-MM-DD').date, 'days');
    return diffInDays > 3;
  }
}
