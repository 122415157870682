import { Attachment, AttachmentPrimitives } from './attachment';

export class LocalAttachment extends Attachment {
  constructor(id: string, filename: string, public content: string, public size: number) {
    super(id, filename);
  }

  isLocal(): boolean {
    return true;
  }

  getImageSrc(): string {
    return this.content;
  }

  getUrl(): string {
    return this.content;
  }

  toPrimitives(): AttachmentPrimitives {
    return {
      id: this.id,
      filename: this.filename,
      size: this.size,
      path: this.content,
    };
  }
}
