import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export abstract class NetworkService {
  protected connected: Subject<boolean> = new Subject();
  connected$: Observable<boolean> = this.connected.asObservable();

  abstract setInitialValue(): void;

  protected setNetworkOnline() {
    // console.warn('-> Network is online!');
    // alert('-> Network is online!');
    this.connected.next(true);
  }

  protected setNetworkOffline() {
    // console.warn('-x Network is offline!');
    // alert('-> Network is offline!');
    this.connected.next(false);
  }
}
