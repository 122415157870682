import { ApiException, ApiExceptionConstructor } from './api-exception';
import { HttpErrorResponse } from '@angular/common/http';

export class RequestTooLargeException extends ApiException implements ApiExceptionConstructor {
  constructor(message?: string, error?: HttpErrorResponse | Error) {
    super(message, error);
    this.name = 'RequestTooLargeException';
  }

  static fromError(error: HttpErrorResponse | Error): RequestTooLargeException {
    return new this(error.message, error);
  }
}
