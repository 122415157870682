export enum RejectTaskActionsShow {
  ALWAYS = 'always',
  NEVER = 'never',
}

export type RejectTaskActionsData = {
  show: RejectTaskActionsShow;
  fields: {
    predefined_observations: {
      show: boolean;
      options: Array<{
        id: string;
        name: string;
        condition: string;
      }>;
    };
    resolutions: {
      show: boolean;
      options: Array<{
        id: string;
        name: string;
      }>;
    };
    observations_for_client: {
      show: boolean;
      mandatory: boolean;
    };
  };
};

export class RejectTaskActions {
  constructor(public readonly value: RejectTaskActionsData) {}

  shouldShowModal() {
    if (this.value.show === RejectTaskActionsShow.ALWAYS) {
      return true;
    }

    if (this.value.show === RejectTaskActionsShow.NEVER) {
      return false;
    }

    // otherwise
    return false;
  }

  shouldShowPredefinedObservations(): boolean {
    return this.value.fields.predefined_observations.show;
  }

  shouldShowResolutions(): boolean {
    if (!this.value.fields.resolutions) {
      return false;
    }

    return this.value.fields.resolutions.show;
  }

  shouldShowObservations(): boolean {
    return this.value.fields.observations_for_client.show;
  }

  isObservationsMandatory(): boolean {
    return this.value.fields.observations_for_client.mandatory;
  }

  optionsForDate(): Array<{ id: string; label: string }> {
    const allOptions = this.value.fields.predefined_observations.options;

    return allOptions.map((o) => ({
      id: o.id,
      label: o.name,
    }));
  }

  optionsForResolutions(): Array<{ id: string; label: string }> {
    const allOptions = this.value.fields.resolutions.options;

    return allOptions.map((o) => ({
      id: o.id,
      label: o.name,
    }));
  }
}
