import { Storage } from 'src/core/storage/domain/storage';
import { Injectable } from '@angular/core';
import { StorableService } from 'src/core/storage/domain/storable-service';
import { MasterDataService } from 'src/core/master-data/domain/master-data-service';
import { Initializable } from 'src/core/common/domain/initializer/initializable';
import { BehaviorSubject, Observable } from 'rxjs';
import { Nullable } from 'src/core/common/domain/types/types';
import { AppService } from '../../../app/app.service';
import { SessionService } from '../../session/domain/session.service';
import { Assignee } from "../../master-data/domain/filters";

export type TasksFilters = {
  workType: string[];
  status: string[];
  assigned: string[];
  date: {
    id: string;
    from: string;
    to: string;
  };
  customRange: {
    from: string;
    to: string;
  };
};

@Injectable({
  providedIn: 'root',
})
export class FiltersService implements StorableService, Initializable {
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  private _filters: Nullable<TasksFilters> = null;
  private readonly KEY_FILTERS = 'filters';

  constructor(
    private storage: Storage,
    private masterDataService: MasterDataService,
    private appService: AppService,
    private session: SessionService
  ) {}

  async init(): Promise<void> {
    const filters = await this.storage.get(this.KEY_FILTERS);
    if (filters !== null) {
      // TODO: Convert object from primitives, check format
      if (filters && !filters.customRange) {
        filters.customRange = {
          from: filters.date.from,
          to: filters.date.to,
        };
      }
      this._filters = filters as TasksFilters;
    }

    this.readySubject.next(true);
  }

  async clear(): Promise<void> {
    this._filters = null;
    await this.storage.remove(this.KEY_FILTERS);
  }

  async resetToDefault() {
    console.warn('Resetting outdated stored filters to default');
    const defaultFilters = this.masterDataService.getDefaultTasksFilters();

    await this.save(defaultFilters);
  }

  get(): Nullable<TasksFilters> {
    // TODO: Valorar si null es valor valido o si en el init deberiamos poner un valor por defecto
    return this._filters;
  }

  async save(filters: TasksFilters): Promise<void> {
    this._filters = filters;
    // To primitives before
    await this.storage.set(this.KEY_FILTERS, filters);
  }

  async saveCustomRange(customRange: { from: string; to: string }) {
    this._filters.customRange = customRange;
    await this.save(this._filters);
  }

  async resetForNonStoredAmbit() {
    /**
     * Date, Work type and Status filters: remain unchanged, to respect what is indicated by the API or selected by the user.
     * Assignment filter: all options are selected so that the tasks not assigned to the user are visible.
     */
    const allFilters = this.masterDataService.getFilters();
    const newFilters = this._filters;
    newFilters.assigned = allFilters.assignees.map((a: Assignee) => a);
    await this.save(newFilters);
  }
}
