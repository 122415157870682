import { ApiException, ApiExceptionConstructor } from "./api-exception";

export class MasterDataIncoherenceException extends ApiException implements ApiExceptionConstructor {
  public static MORE_THAN_ONE_RESOLUTION_BUT_CLOSE_MODAL_NOT_ENABLED = 'MORE_THAN_ONE_RESOLUTION_BUT_CLOSE_MODAL_NOT_ENABLED';
  public static MORE_THAN_ONE_RESOLUTION_BUT_REJECT_MODAL_NOT_ENABLED = 'MORE_THAN_ONE_RESOLUTION_BUT_REJECT_MODAL_NOT_ENABLED';
  public static MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_CLOSE_MODAL = 'MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_CLOSE_MODAL';
  public static MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_REJECT_MODAL = 'MORE_THAN_ONE_RESOLUTION_BUT_RESOLUTION_FIELD_NOT_ENABLED_IN_REJECT_MODAL';

  constructor(message?: string, error?: Error) {
    super(message, error);
    this.name = 'MasterDataIncoherenceException';
  }

  static fromError(error: Error): MasterDataIncoherenceException {
    return new this(error.message, error);
  }
}
