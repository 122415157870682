import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Initializable } from 'src/core/common/domain/initializer/initializable';
import { StorableService } from 'src/core/storage/domain/storable-service';
import { Storage } from '../../storage/domain/storage';
import { Nullable } from '../../common/domain/types/types';

export type Api = {
  id: string;
  isProduction: boolean;
  name: string;
  description: string;
  url: string;
  accessUrl: string;
  basicAuthorizationToken: string;
  usernameToken: string;
  passwordToken: string;
  usernameTokenHeader: string;
  passwordTokenHeader: string;
};

@Injectable({
  providedIn: 'root',
})
export class ApiService implements Initializable, StorableService {
  public static readonly KEY_CURRENT_API = 'api.current';
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean>;
  private readySubject = new BehaviorSubject<boolean>(false);
  private current!: Api;
  // NOTE: first one is the default one
  private apis: Array<Api> = [
    {
      id: 'prod',
      isProduction: true,
      name: 'API Producción (por defecto)',
      description: 'RS no hace cambios aquí',
      url: 'https://api.iss.es/as_frontlineapp_global/v3',
      accessUrl: 'https://api.iss.es/token',
      basicAuthorizationToken: 'UGZzRXR1bEI5VG9NYndEaWVoM0dJZXZWbHdnYTo0V0N6UWFvb1N3S2RfN2o1ZkN0dWw0STlJZUFh',
      usernameToken: 'USR_FLA_PRO',
      passwordToken: 'ISSFLApro!',
      usernameTokenHeader: 'USR_FLA',
      passwordTokenHeader: 'ISSFLA!pro',
    },
    {
      id: 'pre',
      isProduction: false,
      name: 'API Pre-producción',
      description: 'Pruebas RS (e ISS)',
      url: 'https://api.iss.es/as_frontlineapp_global_pre/v3',
      accessUrl: 'https://api.iss.es/token/pre',
      basicAuthorizationToken: 'Wm01WkkwVE8yOVR6RTllVllPTWdFWGVSY0U4YTpSS0R4eVc3aUx5RGN3ZnZxNm9IbkxrSzlNeGNh',
      usernameToken: 'USR_FLA_PRE',
      passwordToken: 'ISSFLApre!',
      usernameTokenHeader: 'USR_FLA',
      passwordTokenHeader: 'ISSFLA!pre',
    },
    {
      id: 'dev',
      isProduction: false,
      name: 'API Desarrollo',
      description: 'Pruebas ISS',
      url: 'https://api.iss.es/as_frontlineapp_global_dev/v3',
      accessUrl: 'https://api.iss.es/token/dev',
      basicAuthorizationToken: 'bFk1YWVnTks5SzBNdVVSYkRvWklGZUxHWHE4YTo3SDFLMGdGS1hLUU9TaHdxdDhiQTdNdnhtR2Nh',
      usernameToken: 'USR_FLA_DEV',
      passwordToken: 'ISSFLAdev!',
      usernameTokenHeader: 'USR_FLA',
      passwordTokenHeader: 'ISSFLA!d',
    },
    {
      id: 'mocked-pre',
      isProduction: false,
      name: 'API Mocked PRE',
      description: 'Pruebas ISS',
      url: 'https://api.pre.ifa.rideosoftware.com',
      accessUrl: 'https://api.pre.ifa.rideosoftware.com/token',
      basicAuthorizationToken: 'bFk1YWVnTks5SzBNdVVSYkRvWklGZUxHWHE4YTo3SDFLMGdGS1hLUU9TaHdxdDhiQTdNdnhtR2Nh',
      usernameToken: 'USR_FLA_DEV',
      passwordToken: 'ISSFLAdev!',
      usernameTokenHeader: 'USR_FLA',
      passwordTokenHeader: 'ISSFLA!d',
    },
    {
      id: 'mocked-qa',
      isProduction: false,
      name: 'API Mocked QA',
      description: 'Pruebas RS',
      url: 'https://api.qa.ifa.rideosoftware.com',
      accessUrl: 'https://api.qa.ifa.rideosoftware.com/token',
      basicAuthorizationToken: 'bFk1YWVnTks5SzBNdVVSYkRvWklGZUxHWHE4YTo3SDFLMGdGS1hLUU9TaHdxdDhiQTdNdnhtR2Nh',
      usernameToken: 'USR_FLA_DEV',
      passwordToken: 'ISSFLAdev!',
      usernameTokenHeader: 'USR_FLA',
      passwordTokenHeader: 'ISSFLA!d',
    },
    {
      id: 'local',
      isProduction: false,
      name: 'API Mocked Local',
      description: 'Máquina local',
      url: 'http://localhost:3010',
      accessUrl: 'http://localhost:3010/token',
      basicAuthorizationToken: 'bFk1YWVnTks5SzBNdVVSYkRvWklGZUxHWHE4YTo3SDFLMGdGS1hLUU9TaHdxdDhiQTdNdnhtR2Nh',
      usernameToken: 'USR_FLA_DEV',
      passwordToken: 'ISSFLAdev!',
      usernameTokenHeader: 'USR_FLA',
      passwordTokenHeader: 'ISSFLA!d',
    },
  ];

  constructor(private storage: Storage) {}

  async init(): Promise<void> {
    await this.initFromStorage();

    this.readySubject.next(true);
  }

  async clear(): Promise<void> {
    // we don't remove anything because it has to have a target API
  }

  getAvailableApis(): Array<Api> {
    return this.apis;
  }

  currentApi(): Api {
    return this.current;
  }

  baseUrl(): string {
    return this.currentApi().url;
  }

  async selectApi(id: string) {
    this.current = this.findById(id);
    await this.save();
  }

  getUsernameToken(): Nullable<string> {
    return this.currentApi().usernameToken;
  }

  getPasswordToken(): Nullable<string> {
    return this.currentApi().passwordToken;
  }

  getUsernameTokenHeader(): Nullable<string> {
    return this.currentApi().usernameTokenHeader;
  }

  getPasswordTokenHeader(): Nullable<string> {
    return this.currentApi().passwordTokenHeader;
  }

  getBasicAuthorizationToken(): string {
    return this.currentApi().basicAuthorizationToken;
  }

  baseAccessUrl(): string {
    return this.currentApi().accessUrl;
  }

  isProduction(): boolean {
    if (!this.currentApi()) {
      return true;
    }

    return this.currentApi().isProduction;
  }

  private async initFromStorage(): Promise<void> {
    const apiId = (await this.storage.get(ApiService.KEY_CURRENT_API)) ?? null;
    if (apiId === null) {
      this.current = this.getDefaultApi();
      await this.save();
    } else {
      this.current = this.findById(apiId);
    }
  }

  private findById(id: string): Api {
    return this.apis.find((api: Api) => api.id === id);
  }

  private async save() {
    await this.storage.set(ApiService.KEY_CURRENT_API, this.current.id);
    await this.initFromStorage();
  }

  private getDefaultApi(): Api {
    return this.apis[0];
  }
}
