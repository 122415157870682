import { HttpErrorResponse } from '@angular/common/http';
import { ApiException, ApiExceptionConstructor } from 'src/core/common/domain/exceptions/api-exception';

export class BadRequestException extends ApiException implements ApiExceptionConstructor {
  constructor(message?: string, error?: HttpErrorResponse | Error) {
    super(message, error);
    this.name = 'BadRequestException';
  }

  static fromError(error: HttpErrorResponse | Error): BadRequestException {
    return new this(error.message, error);
  }

  getErrors(): Array<{
    parameter: string;
    reason: string;
    message: string;
  }> {
    if (this.originalError instanceof HttpErrorResponse) {
      return this.originalError?.error?.errors ?? [];
    }

    return [];
  }
}
