import { TaskStatus } from './task-status';
import { TaskDate } from './task-date';
import { LocalDate } from '../../common/domain/date/local-date';
import { DateRange } from './date-range';
import { CloseTaskData, RejectTaskData } from '../../../app/components/close-task-modal/close-task-modal.component';
import { Attachment } from '../../attachment/domain/attachment';
import { LocalFileAttachment } from '../../attachment/domain/local-file-attachment';
import { Nullable } from '../../common/domain/types/types';
import { DateHelper } from '../../common/domain/date/date-helper';
import { TaskAmbit, TaskAmbitData } from './task-ambit';
import { StringHelper } from '../../common/domain/strings/string-helper';
import { UserInfo } from '../../authentication/domain/user-info';
import { LocalAttachment } from '../../attachment/domain/local-attachment';

export type WorkType = {
  id: string;
  name: string;
};

export type Assignee = {
  id: string;
  name: string;
  email: string;
};

export type Location = {
  full_address: string;
  geolocation?: {
    latitude: number;
    longitude: number;
  };
};

// Definition of object stored in Storage.
// If it changed, it should be handled to prevent corruption of data
export type TaskPrimitives = {
  id: string;
  status: string;
  subject: string;
  work_type: {
    id: string;
    name: string;
  };
  ambit: TaskAmbitData;
  date: {
    expected: string;
    from: string;
    to: string;
  };
  assignee: {
    id: string;
    name: string;
    email: string;
  };
  frequency: Nullable<string>;
  must_be_modified_by_assignee: boolean;
  location: {
    full_address: Nullable<string>;
    geolocation?: { latitude: number; longitude: number };
  };
  started_at: Nullable<string>;
  updated_at: Nullable<string>;
  closed_at: Nullable<string>;
  rejected_at: Nullable<string>;
  created_at: Nullable<string>;
  attachments: Array<{
    id: string;
    filename: string;
    path: string;
    size: number;
  }>;
  display_service_address_in_task: boolean;
  display_postal_address_in_immediate_task: boolean;
  display_postal_address_in_planned_task: boolean;
  display_ambit_in_immediate_task: boolean;
  display_ambit_in_planned_task: boolean;
  close_task_data: {
    predefinedObservations?: Nullable<string>;
    resolutions?: Nullable<string>;
    observations?: Nullable<string>;
    emails?: Nullable<string>;
    signatureType?: Nullable<string>;
    signature?: Nullable<{
      id: string;
      filename: string;
      path: string;
      size: number;
    }>;
  };
  reject_task_data: {
    predefinedObservations?: Nullable<string>;
    resolutions?: Nullable<string>;
    observations?: Nullable<string>;
  };
};

export class Task {
  static readonly LOCAL_ID_PREFIX: string = '--';
  searchableText = '';
  private previousStatus: Nullable<TaskStatus> = null;

  constructor(
    public id: string,
    public status: TaskStatus,
    public readonly subject: string,
    public readonly workType: WorkType,
    public readonly ambit: TaskAmbit,
    public readonly date: TaskDate,
    public readonly assignee: Nullable<Assignee>,
    public readonly mustBeModifiedByAssignee: boolean,
    public readonly location: Location,
    public readonly frequency: Nullable<string>,
    public startedAt: Nullable<LocalDate>,
    public updatedAt: Nullable<LocalDate>,
    public closedAt: Nullable<LocalDate>,
    public rejectedAt: Nullable<LocalDate>,
    public attachments: Array<Attachment>,
    private displayServiceAddressInTask: boolean = false,
    private displayPostalAddressInImmediateTask: boolean = true,
    private displayPostalAddressInPlannedTask: boolean = true,
    private displayAmbitInImmediateTask: boolean = true,
    private displayAmbitInPlannedTask: boolean = true,
    private closeTaskData: Nullable<CloseTaskData>,
    private rejectTaskData: Nullable<RejectTaskData>,
    public createdAt: Nullable<LocalDate> = null
  ) {
    this.initializeExtraData();
    this.prepareSearchableText();
  }

  static fromPrimitives(primitives: TaskPrimitives): Task {
    const closeTaskData = {
      predefinedObservations: null,
      resolutions: null,
      observations: null,
      emails: null,
      signatureType: null,
      signature: null,
    };

    if (primitives.close_task_data) {
      closeTaskData.predefinedObservations = primitives.close_task_data.predefinedObservations;
      closeTaskData.resolutions = primitives.close_task_data.resolutions;
      closeTaskData.observations = primitives.close_task_data.observations;
      closeTaskData.emails = primitives.close_task_data.emails;
      closeTaskData.signatureType = primitives.close_task_data.signatureType;

      if (primitives.close_task_data.signature) {
        closeTaskData.signature = new LocalAttachment(
          primitives.close_task_data.signature.id,
          primitives.close_task_data.signature.filename,
          primitives.close_task_data.signature.path,
          primitives.close_task_data.signature.size
        );
      }
    }

    return new Task(
      primitives.id,
      TaskStatus.fromPrimitives(primitives.status),
      primitives.subject,
      primitives.work_type,
      TaskAmbit.fromPrimitives(primitives.ambit),
      TaskDate.fromPrimitives({
        expected: primitives.date.expected,
        from: primitives.date.from,
        to: primitives.date.to,
      }),
      primitives.assignee,
      primitives.must_be_modified_by_assignee,
      primitives.location,
      primitives.frequency,
      LocalDate.fromString(primitives.started_at),
      LocalDate.fromString(primitives.updated_at),
      LocalDate.fromString(primitives.closed_at),
      LocalDate.fromString(primitives.rejected_at),
      primitives.attachments?.map(
        (attachment) => new LocalFileAttachment(attachment.id, attachment.filename, attachment.path, attachment.size)
      ) ?? [],
      primitives.display_service_address_in_task,
      primitives.display_postal_address_in_immediate_task,
      primitives.display_postal_address_in_planned_task,
      primitives.display_ambit_in_immediate_task,
      primitives.display_ambit_in_planned_task,
      closeTaskData,
      primitives.reject_task_data,
      LocalDate.fromString(primitives.created_at)
    );
  }

  public isPlanned(): boolean {
    return this.workType.id === 'planned' || this.workType.id === 'PL' || this.workType.id === 'FL';
  }

  public isPending() {
    return this.status.isPending();
  }

  public isInProgress() {
    return this.status.isInProgress();
  }

  public isClosed() {
    return this.status.isClosed();
  }

  public isRejected() {
    return this.status.isRejected();
  }

  start() {
    if (this.isInProgress()) {
      return;
    }

    this.storePreviousStatus();
    this.status = TaskStatus.inProgress();
    if (!this.startedAt) {
      this.startedAt = LocalDate.now();
    }
    this.touch();
  }

  stop() {
    if (this.isPending()) {
      return;
    }

    this.storePreviousStatus();
    this.status = TaskStatus.pending();
    this.touch();
  }

  close() {
    if (this.isClosed()) {
      return;
    }

    this.storePreviousStatus();
    this.status = TaskStatus.closed();
    if (!this.closedAt) {
      this.closedAt = LocalDate.now();
    }
    this.touch();
  }

  toPrimitives(): TaskPrimitives {
    const closeTaskDataPrimitives = {
      predefinedObservations: null,
      resolutions: null,
      observations: null,
      emails: null,
      signatureType: null,
      signature: null,
    };

    const closeTaskData = this.closeTaskData;
    if (closeTaskData) {
      closeTaskDataPrimitives.predefinedObservations = closeTaskData.predefinedObservations;
      closeTaskDataPrimitives.resolutions = closeTaskData.resolutions;
      closeTaskDataPrimitives.observations = closeTaskData.observations;
      closeTaskDataPrimitives.emails = closeTaskData.emails;
      closeTaskDataPrimitives.signatureType = closeTaskData.signatureType;
      closeTaskDataPrimitives.signature = closeTaskData.signature ? closeTaskData.signature.toPrimitives() : null;
    }

    return {
      id: this.id,
      status: this.status.toPrimitives(),
      subject: this.subject,
      work_type: this.workType,
      ambit: this.ambit.toPrimitives(),
      assignee: this.assignee,
      must_be_modified_by_assignee: this.mustBeModifiedByAssignee,
      location: this.location,
      frequency: this.frequency,
      date: this.date.toPrimitives(),
      created_at: this.createdAt?.atom ?? null,
      started_at: this.startedAt?.atom ?? null,
      updated_at: this.updatedAt?.atom ?? null,
      closed_at: this.closedAt?.atom ?? null,
      rejected_at: this.rejectedAt?.atom ?? null,
      attachments: this.attachments.map((attachment) => attachment.toPrimitives()),
      display_service_address_in_task: this.displayServiceAddressInTask,
      display_postal_address_in_immediate_task: this.displayPostalAddressInImmediateTask,
      display_postal_address_in_planned_task: this.displayPostalAddressInPlannedTask,
      display_ambit_in_immediate_task: this.displayAmbitInImmediateTask,
      display_ambit_in_planned_task: this.displayAmbitInPlannedTask,
      close_task_data: closeTaskDataPrimitives,
      reject_task_data: this.rejectTaskData,
    };
  }

  inRange(range: DateRange): boolean {
    if (range === null) {
      return false;
    }

    return this.date.expected.inRange(range);
  }

  updateCloseTaskData(data: CloseTaskData) {
    this.attachments = data.attachments ?? [];
    delete data.attachments;
    this.closeTaskData = data;
  }

  getCloseTaskData(): CloseTaskData {
    const closeTaskData = this.closeTaskData;
    if (closeTaskData) {
      closeTaskData.attachments = this.attachments;
    }

    return closeTaskData;
  }

  updateRejectTaskData(data: RejectTaskData) {
    this.rejectTaskData = data;
  }

  getRejectTaskData(): RejectTaskData {
    return this.rejectTaskData;
  }

  getAttachmentsDirectory(): string {
    return this.id;
  }

  fulfillsText(substringsToSearch: string[]): boolean {
    return substringsToSearch.every((subcadena) => this.searchableText.includes(subcadena.toLowerCase()));
  }

  expectedDate(): string {
    return DateHelper.expectedDate(this.date);
  }

  hasGeolocationDefined(): boolean {
    return (
      this.location.geolocation !== null &&
      this.location.geolocation.longitude !== null &&
      this.location.geolocation.latitude !== null
    );
  }

  reject() {
    this.storePreviousStatus();
    this.status = TaskStatus.rejected();
    if (!this.rejectedAt) {
      this.rejectedAt = LocalDate.now();
    }
    this.touch();
  }

  storePreviousStatus() {
    this.previousStatus = this.status;
  }

  rollback() {
    if (this.isClosed()) {
      this.closedAt = null;
    } else if (this.isInProgress()) {
      this.startedAt = null;
    } else if (this.isRejected()) {
      this.rejectedAt = null;
    } else if (this.isPending()) {
      // nothing to do
    }
    this.status = this.previousStatus;
    this.touch();
  }

  belongsTo(user: UserInfo): boolean {
    if (this.assignee === null) {
      return false;
    }

    // TODO: Store searchable email (in both task and user)
    return this.assignee.email.toLowerCase() === user.email.toLowerCase();
  }

  canBeModifiedBy(user: UserInfo): boolean {
    if (this.assignee === null) {
      return true;
    }

    return this.belongsTo(user);
  }

  changeId(newTaskId: string) {
    this.id = newTaskId;
  }

  isLocal(): boolean {
    return this.id.startsWith(Task.LOCAL_ID_PREFIX);
  }

  private touch(): void {
    this.updatedAt = LocalDate.now();
  }

  private prepareSearchableText() {
    const strings = [
      StringHelper.latinize(this.subject.toLowerCase()),
      StringHelper.latinize(this.expectedDate().toLowerCase()),
      StringHelper.latinize(this.frequency?.toLowerCase()),
    ];

    // We add to searchable text only if it is not hidden using displayAmbit… configuration.
    if (
      (this.isPlanned() && this.displayAmbitInPlannedTask) ||
      (!this.isPlanned() && this.displayAmbitInImmediateTask)
    ) {
      strings.push(StringHelper.latinize(this.ambit.printableAmbit().toLowerCase()));
    }

    if (this.assignee !== null) {
      strings.push(StringHelper.latinize(this.assignee.name.toLowerCase()));
    }

    if (this.displayServiceAddressInTask) {
      strings.push(...[StringHelper.latinize(this.ambit.getServiceAddressName().toLowerCase())]);

      if (this.location.full_address !== null) {
        // happens when task is created by user
        strings.push(
          ...[
            // We add to searchable text even if it's hidden using displayPostalAddress… configuration because
            // the link to the postal address will still be there.
            StringHelper.latinize(this.location.full_address.toLowerCase()),
          ]
        );
      }
    }

    this.searchableText = strings.join(' ');
  }

  private initializeExtraData() {
    this.closeTaskData = {
      resolutions: null,
      predefinedObservations: null,
      observations: null,
      emails: null,
      signatureType: null,
      signature: null,
      attachments: null,
    };

    this.rejectTaskData = {
      predefinedObservations: null,
      resolutions: null,
      observations: null,
    };
  }
}
