import { LocalDate } from 'src/core/common/domain/date/local-date';

export abstract class QueueElement {
  label: string;
  publicId: string;
  errors: Array<{
    parameter: string;
    reason: string;
    message: string;
  }>;

  protected constructor(
    public readonly id: string,
    public readonly createdAt: LocalDate,
    public readonly type: string
  ) {}

  static fromPayload(payload: any) {
    throw new Error('You need to implement fromPayload');
  }

  abstract toPrimitives(): any;
}
