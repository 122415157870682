import { Nullable } from '../../common/domain/types/types';
import { QueueElement } from './queue-element';

export class Queue {
  _elements: QueueElement[] = [];

  constructor(initialElements: QueueElement[] = []) {
    this._elements = initialElements;
  }

  elements(): QueueElement[] {
    return this._elements;
  }

  enqueue(element: QueueElement): void {
    this._elements.push(element);
  }

  dequeue(): Nullable<QueueElement> {
    if (this._elements.length === 0) {
      return null;
    }

    const element = this._elements[0];
    this._elements.splice(0, 1);

    return element;
  }

  nextElement(): Nullable<QueueElement> {
    if (this._elements.length === 0) {
      return null;
    }

    return this._elements[0];
  }

  isEmpty(): boolean {
    return this.size() === 0;
  }

  size(): number {
    return this._elements.length;
  }

  removeElement(queueElement: QueueElement) {
    const index = this.findElementIndex(queueElement);
    if (index === -1) {
      return;
    }

    this._elements.splice(index, 1);
  }

  element(index: number): Nullable<QueueElement> {
    if (this._elements.length < index) {
      return null;
    }

    return this._elements[index];
  }

  updateElement(queueElement: QueueElement) {
    const index = this.findElementIndex(queueElement);
    if (index === -1) {
      return;
    }

    this._elements[index] = queueElement;
  }

  private findElementIndex(queueElement: QueueElement) {
    return this._elements.findIndex((element) => element.id === queueElement.id);
  }
}
