import { Injectable } from '@angular/core';
import { Task } from 'src/core/master-data/domain/task';
import { TaskRepository } from 'src/core/tasks/domain/task-repository';
import { GarbageCollectorService } from '../../../garbage-collector/domain/garbage-collector.service';
import { TaskService } from '../../domain/task.service';
import { QueueService } from '../../../queue/domain/queue.service';
import { CloseTaskRequest } from './close-task-request';
import { TimeoutException } from '../../../common/domain/exceptions/timeout-exception';
import { NoConnectionException } from '../../../common/domain/exceptions/no-connection-exception';
import { CloseTaskQueueElement } from '../../../queue/domain/elements/close-task-queue-element';
import { BadRequestException } from '../../../common/domain/exceptions/bad-request-exception';

@Injectable({
  providedIn: 'any',
})
export class CloseTask {
  private isSavedToServer = false;
  private isClosedToServer = false;
  private isRetry = false;
  private task: Task;

  constructor(
    private readonly taskRepository: TaskRepository,
    private readonly garbageCollector: GarbageCollectorService,
    private readonly taskService: TaskService,
    private readonly queueService: QueueService
  ) {}

  async execute(request: CloseTaskRequest): Promise<void> {
    this.task = request.task;
    const queueElement = request.queueElement;
    this.isRetry = queueElement !== null;
    this.isSavedToServer = queueElement !== null ? queueElement.operationStatus.isSavedToServer : false;
    this.isClosedToServer = queueElement !== null ? queueElement.operationStatus.isClosedToServer : false;

    try {
      if (!this.isRetry) {
        this.task.close();
        await this.saveTaskIntoLocalDevice();
      }

      const taskIsInQueue = this.queueService.hasQueueElementWithTaskId(this.task.id);
      if (taskIsInQueue && !this.isRetry) {
        await this.enqueueOperation();
        return;
      }

      await this.saveTaskToExternalServer(this.task);
      await this.closeTaskToExternalServer(this.task);
      this.garbageCollector.addDirectoryToDelete(this.task.getAttachmentsDirectory());
    } catch (exception) {
      // exceptions during retrying do not enqueue or rollback the task
      if (this.isRetry) {
        if (exception instanceof BadRequestException) {
          // store the error in the enqueued element
          await this.updateQueueElementWithException(exception, queueElement);
        }
        throw exception; // propagate exception without doing anything else
      }

      if (this.isConnectionProblem(exception)) {
        await this.enqueueOperation();
        return;
      }

      // otherwise, rollback
      this.task.rollback();
      await this.saveTaskIntoLocalDevice();
      throw exception; // propagate exception
    }
  }

  private async updateQueueElementWithException(exception: BadRequestException, queueElement: CloseTaskQueueElement) {
    const errors = exception.getErrors();
    const operationStatus = {
      isSavedToServer: this.isSavedToServer,
      isClosedToServer: this.isClosedToServer,
    };
    queueElement.update(this.task, operationStatus, errors);
    await this.queueService.updateQueueElement(queueElement);
  }

  private async enqueueOperation(
    errors: Array<{
      parameter: string;
      reason: string;
      message: string;
    }> = []
  ) {
    const operationStatus = {
      isSavedToServer: this.isSavedToServer,
      isClosedToServer: this.isClosedToServer,
    };
    await this.queueService.enqueue(CloseTaskQueueElement.fromTask(this.task, operationStatus, errors));
  }

  private isConnectionProblem(exception: any): boolean {
    return exception instanceof TimeoutException || exception instanceof NoConnectionException;
  }

  private async saveTaskIntoLocalDevice() {
    await this.taskService.saveTask(this.task);
  }

  private async saveTaskToExternalServer(task: Task) {
    if (this.isSavedToServer) {
      return;
    }
    await this.taskRepository.save(task);
    this.isSavedToServer = true;
  }

  private async closeTaskToExternalServer(task: Task) {
    if (this.isClosedToServer) {
      return;
    }
    await this.taskRepository.close(task);
    this.isClosedToServer = true;
  }
}
