import { Assignee, FilterDate } from './filters';

export type DefaultFiltersPrimitives = {
  work_types: Array<string>;
  statuses: Array<string>;
  date: string;
  assignee: Array<string>;
};

export class DefaultFilters {
  constructor(
    public readonly workTypes: Array<string>,
    public readonly statuses: Array<string>,
    public readonly assignee: Array<Assignee>,
    public readonly date: FilterDate
  ) {}

  static fromPrimitives(primitives: DefaultFiltersPrimitives) {
    return new this(
      primitives.work_types,
      primitives.statuses,
      primitives.assignee as Array<Assignee>,
      primitives.date as FilterDate
    );
  }

  toPrimitives(): DefaultFiltersPrimitives {
    return {
      work_types: this.workTypes,
      statuses: this.statuses,
      date: this.date,
      assignee: this.assignee,
    };
  }
}
