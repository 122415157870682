import { TaskStatus } from './task-status';
import { DefaultFilters } from './default-filters';
import { WorkType } from '../work-type';

export enum FilterDate {
  TODAY = 'today',
  THIS_WEEK = 'this_week',
  THIS_MONTH = 'this_month',
  LAST_MONTH = 'last_month',
  RANGE = 'range',
  RANGE_SEVEN_DAYS_BEFORE_AND_AFTER = 'range_7_days_before_and_after',
}

export enum Assignee {
  ME = 'me',
  OTHERS = 'others',
  NONE = 'none',
}

export class Filters {
  public readonly dates: Array<FilterDate> = [
    FilterDate.TODAY,
    FilterDate.THIS_WEEK,
    FilterDate.THIS_MONTH,
    FilterDate.LAST_MONTH,
    FilterDate.RANGE_SEVEN_DAYS_BEFORE_AND_AFTER,
  ];

  constructor(
    public readonly defaultFilters: DefaultFilters,
    public readonly workTypes: Array<WorkType>,
    public readonly statuses: Array<TaskStatus>,
    public readonly assignees: Array<Assignee>
  ) {}

  static fromPrimitives(filters: {
    default: {
      work_types: Array<string>;
      statuses: Array<string>;
      date: string;
      assignee: Array<string>;
    };
    work_types: Array<{ id: string; name: string }>;
    statuses: Array<string>;
    dates: Array<string>;
    assignees: Array<string>;
  }) {
    return new Filters(
      DefaultFilters.fromPrimitives(filters.default),
      filters.work_types,
      filters.statuses.map((status) => TaskStatus.fromPrimitives(status)),
      filters.assignees.map((assignee) => assignee as Assignee)
    );
  }

  toPrimitives() {
    return {
      default: this.defaultFilters.toPrimitives(),
      work_types: this.workTypes,
      statuses: this.statuses.map((status) => status.toPrimitives()),
      assignees: this.assignees,
    };
  }
}
