import { Injectable } from '@angular/core';
import { Storage as AngularStorage } from '@ionic/storage-angular';
import { Nullable } from 'src/core/common/domain/types/types';
import { Storage } from 'src/core/storage/domain/storage';
import { ApiService } from '../../api/domain/api.service';

@Injectable({
  providedIn: 'root',
})
export class IonicStorageService extends Storage {
  private storage: Nullable<AngularStorage> = null;

  constructor(private internalStorage: AngularStorage) {
    super();
    this.init().then().catch();
  }

  async init(): Promise<void> {
    this.storage = await this.internalStorage.create();
  }

  async reset(): Promise<void> {
    if (!this.storage) await this.init();

    const keysToKeep = [ApiService.KEY_CURRENT_API];
    const allKeys = await this.storage.keys();
    const keysToDelete = allKeys.filter((key) => !keysToKeep.includes(key));
    await Promise.all(keysToDelete.map((key) => this.storage.remove(key)));
  }

  public async ready(): Promise<void> {}

  async set(key: string, value: any) {
    if (!this.storage) await this.init();

    await this.storage?.set(key, value);
  }

  async get(key: string): Promise<Nullable<any>> {
    if (!this.storage) await this.init();

    return this.storage?.get(key);
  }

  async remove(key: string): Promise<void> {
    if (!this.storage) await this.init();

    return this.storage?.remove(key);
  }
}
