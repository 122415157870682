export abstract class HttpClient {
  abstract get<ResponseType>(
    url: string,
    params?: Record<string, unknown>,
    headers?: { [name: string]: string | string[] },
    requestIsBasicAuthenticated?: boolean,
    requestIsAuthenticated?: boolean
  ): Promise<ResponseType>;

  abstract post<ResponseType = void>(
    url: string,
    data: unknown,
    headers?: { [name: string]: string | string[] },
    requestIsBasicAuthenticated?: boolean,
    requestIsAuthenticated?: boolean
  ): Promise<ResponseType>;
}
