import { Task } from 'src/core/master-data/domain/task';

export class StopTaskRequest {
  constructor(
    public readonly task: Task,
    public readonly isRetry: boolean,
    public readonly isSaveDone: boolean,
    public readonly isStopDone: boolean
  ) {}

  static fromTask(task: Task) {
    return new this(task, false, false, false);
  }

  static retry(task: Task, isSaveDone: boolean, isStopDone: boolean) {
    return new this(task, true, isSaveDone, isStopDone);
  }
}
