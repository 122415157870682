import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-mandatory-update-modal',
  styleUrls: ['./mandatory-update-modal.scss'],
  templateUrl: 'mandatory-update-modal.html',
})
export class MandatoryUpdateModalPage implements OnInit {
  constructor(private platform: Platform) {}

  ngOnInit(): void {
    this.disableHardwareBackButton();
  }

  private disableHardwareBackButton(): void {
    // hardware back button (discard returned observable because this page blocks APP until it is updated)
    this.platform.backButton.subscribeWithPriority(101, () => {});
  }
}
