import { Injectable } from '@angular/core';
import { Storage } from 'src/core/storage/domain/storage';
import { UserInfo } from 'src/core/authentication/domain/user-info';
import { OriginInfo } from 'src/core/authentication/domain/origin-info';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserLoggedInEvent } from 'src/core/events/user-logged-in.event';
import { EventsService } from 'src/core/events/events.service';
import { AccessToken } from 'src/core/authentication/domain/access-token';
import { RefreshToken } from 'src/core/authentication/domain/refresh-token';
import { AuthToken } from 'src/core/authentication/domain/auth-token';
import { AuthInfo } from 'src/core/authentication/domain/auth-info';
import { Nullable } from 'src/core/common/domain/types/types';
import { StorableService } from '../../storage/domain/storable-service';
import { Initializable } from '../../common/domain/initializer/initializable';

@Injectable({
  providedIn: 'root',
})
export class SessionService implements StorableService, Initializable {
  private static readonly KEY_IS_LOGGED_IN = 'session.is_logged_in';
  private static readonly KEY_API_ACCESS_TOKEN = 'session.api_access_token';
  private static readonly KEY_AUTH_TOKEN = 'session.auth_token';
  private static readonly KEY_REFRESH_TOKEN = 'session.refresh_token';
  private static readonly KEY_LOGGED_USER = 'session.user';
  private static readonly KEY_LOGGED_ORIGIN = 'session.origin';

  private isLoggedIn = false;
  private userInfo: Nullable<UserInfo> = null;
  private originInfo: Nullable<OriginInfo> = null;
  private authToken: Nullable<AuthToken> = null;
  private refreshToken: Nullable<RefreshToken> = null;
  private apiAccessToken: Nullable<AccessToken> = null;
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();

  constructor(private storage: Storage, private events: EventsService) {}

  async init(): Promise<void> {
    this.isLoggedIn = (await this.storage.get(SessionService.KEY_IS_LOGGED_IN)) ?? false;
    this.apiAccessToken = AccessToken.from(await this.storage.get(SessionService.KEY_API_ACCESS_TOKEN));
    this.authToken = AuthToken.from(await this.storage.get(SessionService.KEY_AUTH_TOKEN));
    this.refreshToken = RefreshToken.from(await this.storage.get(SessionService.KEY_REFRESH_TOKEN));
    this.userInfo = UserInfo.from(await this.storage.get(SessionService.KEY_LOGGED_USER));
    if (this.userInfo) {
      this.events.publishLoggedInEvent(new UserLoggedInEvent(this.userInfo));
    }
    this.originInfo = OriginInfo.from(await this.storage.get(SessionService.KEY_LOGGED_ORIGIN));

    this.readySubject.next(true);
  }

  isAuthenticated(): boolean {
    // return this.apiAccessToken !== null && this.authToken !== null && this.userInfo !== null;
    return this.isLoggedIn;
  }

  async isAuthenticatedPromise(): Promise<boolean> {
    return (await this.storage.get(SessionService.KEY_IS_LOGGED_IN)) ?? false;
  }

  async clear(): Promise<void> {
    this.isLoggedIn = false;
    this.userInfo = null;
    this.originInfo = null;
    this.apiAccessToken = null;
    this.authToken = null;
    this.refreshToken = null;
    await this.storage.remove(SessionService.KEY_API_ACCESS_TOKEN);
    await this.storage.remove(SessionService.KEY_AUTH_TOKEN);
    await this.storage.remove(SessionService.KEY_REFRESH_TOKEN);
    await this.storage.remove(SessionService.KEY_IS_LOGGED_IN);
    await this.storage.remove(SessionService.KEY_LOGGED_USER);
    await this.storage.remove(SessionService.KEY_LOGGED_ORIGIN);
  }

  async saveAccessToken(apiAccessToken: AccessToken) {
    this.apiAccessToken = apiAccessToken;
    await this.storage.set(SessionService.KEY_API_ACCESS_TOKEN, apiAccessToken.value);
  }

  async saveUserInfo(userInfo: UserInfo) {
    this.userInfo = userInfo;
    await this.storage.set(SessionService.KEY_LOGGED_USER, userInfo.toPrimitives());
  }

  async saveOriginInfo(originInfo: OriginInfo) {
    this.originInfo = originInfo;
    await this.storage.set(SessionService.KEY_LOGGED_ORIGIN, originInfo.toPrimitives());
  }

  async saveAuthInfo(authInfo: AuthInfo) {
    this.authToken = authInfo.authToken;
    this.refreshToken = authInfo.refreshToken;
    this.isLoggedIn = true;
    await this.storage.set(SessionService.KEY_AUTH_TOKEN, authInfo.authToken.value);
    await this.storage.set(SessionService.KEY_REFRESH_TOKEN, authInfo.refreshToken.value);
    await this.storage.set(SessionService.KEY_IS_LOGGED_IN, true);

    this.events.publishLoggedInEvent(new UserLoggedInEvent(this.userInfo));
  }

  getAuthToken(): Nullable<AuthToken> {
    return this.authToken;
  }

  getRefreshToken(): Nullable<RefreshToken> {
    return this.refreshToken;
  }

  getAccessToken(): Nullable<AccessToken> {
    return this.apiAccessToken;
  }

  getUser(): Nullable<UserInfo> {
    return this.userInfo;
  }

  getOrigin(): Nullable<OriginInfo> {
    return this.originInfo;
  }

  getCurrentLanguage(): string {
    return 'es'; // hardcoded atm
  }

  isSuperAdmin(): boolean {
    if (!this.getUser()) {
      return false;
    }

    const username = this.getUser().username;
    const superAdmins = ['carlos.hospital', 'javier.cristalero', 'FEXPOSITO', 'JESPINEL', 'JBURGOS'];
    return superAdmins.includes(username);
  }
}
