import { Task } from 'src/core/master-data/domain/task';
import { CreateTaskQueueElement } from '../../../queue/domain/elements/create-task-queue-element';

export class CreateTaskRequest {
  constructor(public readonly task: Task, public queueElement: CreateTaskQueueElement) {}

  static fromTask(task: Task) {
    return new this(task, null);
  }

  static retry(element: CreateTaskQueueElement) {
    return new this(element.task, element);
  }
}
