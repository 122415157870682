import { NetworkService } from '../domain/network.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BrowserNetworkService extends NetworkService {
  constructor() {
    // ionic documentation says network plugin supports browser, but it doesn't
    super();

    this.addEventListeners();
  }

  setInitialValue(): void {
    this.setNetworkOnline();
  }

  private addEventListeners() {
    window.addEventListener('offline', () => this.setNetworkOffline());
    window.addEventListener('online', () => this.setNetworkOnline());
  }

  private removeEventListeners() {
    window.removeEventListener('offline', () => {});
    window.removeEventListener('online', () => {});
  }
}
