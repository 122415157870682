import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { Router, RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppVersion } from '@awesome-cordova-plugins/app-version/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';
import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Drivers } from '@ionic/storage';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateParser } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AVAILABLE_LANGUAGE } from './available-languages';
import { SentryIonicErrorLogger } from 'src/app/sentry-ionic-error-logger.service';
import * as Sentry from '@sentry/angular-ivy';
import { CustomErrorHandler } from './custom-error-handler.service';
import { Storage } from 'src/core/storage/domain/storage';
import { IonicStorageService } from 'src/core/storage/infrastructure/ionic-storage.service';
import { HttpClient as DomainHttpClient } from 'src/core/common/domain/http/http-client';
import { AngularHttpClient } from 'src/core/common/infrastructure/http/angular-http-client';
import { IdGenerator } from 'src/core/common/domain/uuid/id-generator';
import { UuidGenerator } from 'src/core/common/infrastructure/uuid/uuid-generator';
import { MasterDataRepository } from 'src/core/master-data/domain/master-data-repository';
import { HttpMasterDataRepository } from 'src/core/master-data/infrastructure/http-master-data-repository';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';
import { Translation } from 'src/core/common/domain/translation/translation';
import { AngularTranslation } from 'src/core/common/infrastructure/translation/angular-translation';
import { Camera } from '@awesome-cordova-plugins/camera/ngx';
import { Chooser } from '@awesome-cordova-plugins/chooser/ngx';
import { File } from '@awesome-cordova-plugins/file/ngx';
import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator/ngx';
import { CameraService } from 'src/core/camera/domain/camera.service';
import { FilesystemService } from 'src/core/filesystem/domain/filesystem.service';
import { SignaturePadModule } from 'angular-signaturepad';
import { CustomTranslateParser } from 'src/core/common/infrastructure/translation/custom-translate-parser';
import { ApiService } from '../core/api/domain/api.service';
import { MasterDataService } from '../core/master-data/domain/master-data-service';
import { SystemService } from '../core/system/system.service';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { BrowserNetworkService } from '../core/connection/infrastructure/browser-network.service';
import { CordovaNetworkService } from '../core/connection/infrastructure/cordova-network.service';
import { ConnectivityService } from '../core/connection/domain/connectivity.service';
import { TaskRepository } from '../core/tasks/domain/task-repository';
import { HttpTaskRepository } from '../core/tasks/infrastructure/http-task-repository';

Sentry.init({
  dsn: 'https://f16889f6d528a022dfa477fbcda15273@o4506314605920256.ingest.sentry.io/4506314626039808',
  environment: environment.name,
  release: SystemService.APP_VERSION,
  normalizeDepth: 6, // Default 3
  dist: '1',
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
      tracePropagationTargets: ['*'],
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json');

@NgModule({
  declarations: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    IonicStorageModule.forRoot({
      name: 'iss_frontline_app' + (environment.database_name_suffix ? `_${environment.database_name_suffix}` : ''),
      driverOrder: [Drivers.IndexedDB],
    }),
    TranslateModule.forRoot({
      defaultLanguage: AVAILABLE_LANGUAGE.ES,
      parser: {
        provide: TranslateParser,
        useClass: CustomTranslateParser,
      },
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SignaturePadModule,
  ],
  providers: [
    AppVersion,
    // #RS_BUILD_SETTING Enable when DIST build, the next line. Comment it if you want to debug while in DEV
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    SentryIonicErrorLogger,
    FirebaseX,
    Camera,
    Chooser,
    ApiService,
    Diagnostic,
    File,
    CameraService,
    ConnectivityService,
    FilesystemService,
    NFC,
    Network,
    BrowserNetworkService,
    CordovaNetworkService,
    StatusBar,
    LaunchNavigator,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: DomainHttpClient,
      useClass: AngularHttpClient,
    },
    {
      provide: Storage,
      useClass: IonicStorageService,
    },
    {
      provide: IdGenerator,
      useClass: UuidGenerator,
    },
    {
      provide: Translation,
      useClass: AngularTranslation,
    },
    {
      provide: MasterDataService,
      useClass: MasterDataService,
    },
    {
      provide: MasterDataRepository,
      useClass: HttpMasterDataRepository,
    },
    {
      provide: TaskRepository,
      useClass: HttpTaskRepository,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
