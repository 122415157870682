import { Injectable } from '@angular/core';
import { HttpClient as HttpClientAngular, HttpHeaders, HttpParams } from '@angular/common/http';
import { lastValueFrom, Observable, tap } from 'rxjs';
import { HttpClient } from 'src/core/common/domain/http/http-client';
import { SessionService } from 'src/core/session/domain/session.service';
import { ApiService } from '../../../api/domain/api.service';
import { SystemService } from '../../../system/system.service';
import { EventsService } from '../../../events/events.service';
import { AppIsOutdatedEvent } from "../../../events/app-is-outdated.event";
import { NewAppVersionAvailableEvent } from "../../../events/new-app-version-available.event";

@Injectable({
  providedIn: 'any',
})
export class AngularHttpClient implements HttpClient {
  constructor(
    private readonly http: HttpClientAngular,
    private readonly session: SessionService,
    private readonly apiService: ApiService,
    private readonly eventsService: EventsService
  ) {}

  async get<ResponseType>(
    url: string,
    params: Record<string, unknown> = {},
    headers?: { [name: string]: string | string[] },
    requestIsBasicAuthenticated: boolean = false,
    requestIsAuthenticated: boolean = false
  ): Promise<ResponseType> {
    const source = this.http
      .get<ResponseType>(url, {
        params: {
          ...(params as unknown as HttpParams),
        },
        headers: await this.getHttpHeaders(headers, requestIsBasicAuthenticated, requestIsAuthenticated),
      })
      .pipe(
        tap((response: any) => {
          const code = response?.action?.code ?? null;
          if (code === 'APP_IS_OUTDATED') {
            this.eventsService.publishAppIsOutdated(new AppIsOutdatedEvent());
          }
          if (code === 'NEW_APP_VERSION_AVAILABLE') {
            this.eventsService.publishNewAppVersionIsAvailable(new NewAppVersionAvailableEvent());
          }
        })
      );
    return lastValueFrom(source);
  }

  async post<ResponseType = void>(
    url: string,
    data: unknown,
    headers?: { [name: string]: string | string[] },
    requestIsBasicAuthenticated: boolean = false,
    requestIsAuthenticated: boolean = false
  ): Promise<ResponseType> {
    const observable: Observable<ResponseType> = this.http
      .post<ResponseType>(url, data, {
        headers: await this.getHttpHeaders(headers, requestIsBasicAuthenticated, requestIsAuthenticated),
      })
      .pipe(
        tap((response: any) => {
          const code = response?.action?.code ?? null;
          if (code === 'APP_IS_OUTDATED') {
            this.eventsService.publishAppIsOutdated(new AppIsOutdatedEvent());
          }
          if (code === 'NEW_APP_VERSION_AVAILABLE') {
            this.eventsService.publishNewAppVersionIsAvailable(new NewAppVersionAvailableEvent());
          }
        })
      );

    return lastValueFrom(observable);
  }

  private async getHttpHeaders(
    headers?: { [name: string]: string | string[] },
    requestIsBasicAuthenticated: boolean = false,
    requestIsAuthenticated: boolean = false
  ): Promise<HttpHeaders> {
    let httpHeaders = new HttpHeaders(headers);

    // otherwise the API will fail
    httpHeaders = httpHeaders.set('Accept', 'application/json');

    if (requestIsBasicAuthenticated) {
      httpHeaders = this.addCommonHeaders(httpHeaders);
      httpHeaders = this.addBasicAuthorizationHeader(httpHeaders);
    }

    if (requestIsAuthenticated) {
      httpHeaders = this.addAuthorizationHeader(httpHeaders);
    }

    return httpHeaders;
  }

  private addBasicAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    const accessToken = this.session.getAccessToken();
    headers = headers.set('Authorization', `Bearer ${accessToken.value}`);
    headers = headers.set('usernameToken', this.apiService.getUsernameTokenHeader());
    headers = headers.set('passwordToken', this.apiService.getPasswordTokenHeader());

    return headers;
  }

  private addCommonHeaders(headers: HttpHeaders): HttpHeaders {
    const currentLanguage = this.session.getCurrentLanguage();
    headers = headers.set('X-Language', currentLanguage);
    headers = headers.set('X-App-Version', SystemService.APP_VERSION);

    return headers;
  }

  private addAuthorizationHeader(headers: HttpHeaders): HttpHeaders {
    const authToken = this.session.getAuthToken();
    headers = headers.set('X-Auth-Token', authToken.value);

    return headers;
  }
}
