import { HttpErrorResponse } from '@angular/common/http';
import { ApiExceptionConstructor } from 'src/core/common/domain/exceptions/api-exception';
import { BadRequestException } from './bad-request-exception';

export class TaskAlreadyClosedException extends BadRequestException implements ApiExceptionConstructor {
  constructor(message?: string, error?: HttpErrorResponse | Error) {
    super(message, error);
    this.name = 'TaskAlreadyClosedException';
  }

  static fromError(error: HttpErrorResponse | Error): TaskAlreadyClosedException {
    return new this(error.message, error);
  }
}
