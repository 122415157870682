import { Task } from 'src/core/master-data/domain/task';
import { CloseTaskQueueElement } from '../../../queue/domain/elements/close-task-queue-element';
import { Nullable } from '../../../common/domain/types/types';

export class CloseTaskRequest {
  constructor(public readonly task: Task, public readonly queueElement: Nullable<CloseTaskQueueElement>) {}

  static fromTask(task: Task) {
    return new this(task, null);
  }

  static retry(element: CloseTaskQueueElement) {
    return new this(element.task, element);
  }
}
