import { LocalDate } from '../../common/domain/date/local-date';
import { DateRange } from './date-range';

export class TaskDate {
  constructor(public readonly expected: LocalDate, public readonly range: DateRange) {}

  static fromPrimitives(primitives: { expected: string; from: string; to: string }) {
    const expectedDate = LocalDate.fromString(primitives.expected).discardTime();
    return new TaskDate(
      expectedDate,
      DateRange.fromPrimitives({ from: primitives.from, to: primitives.to })
    );
  }

  toPrimitives() {
    return {
      expected: this.expected.ymd,
      from: this.range.from.atom,
      to: this.range.to.atom,
    };
  }
}
