import { Nullable } from 'src/core/common/domain/types/types';

export class UserInfo {
  constructor(
    public readonly id: string,
    public readonly username: string,
    public readonly fullName: string,
    public readonly name: string,
    public readonly surname: string,
    public readonly email: string
  ) {}

  static from(data: Nullable<any>) {
    if (data === null) {
      return null;
    }
    // TODO: Ensure all needed fields are present. Otherwise set fallback values (ACL)
    return new this(data.id, data.username, data.fullName, data.name, data.surname, data.email);
  }

  toPrimitives() {
    return {
      id: this.id,
      username: this.username,
      fullName: this.fullName,
      name: this.name,
      surname: this.surname,
      email: this.email,
    };
  }
}
