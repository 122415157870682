import { LocalDate } from 'src/core/common/domain/date/local-date';
import { Task, TaskPrimitives } from 'src/core/master-data/domain/task';
import { UuidGenerator } from '../../../common/infrastructure/uuid/uuid-generator';
import { TaskQueueElement } from './task-queue-element';

type StartTaskQueueElementPrimitives = {
  id: string;
  created_at: string;
  type: string;
  task: TaskPrimitives;
  operation_status: {
    isSavedToServer: boolean;
    isStartedToServer: boolean;
  };
  errors: Array<{
    parameter: string;
    reason: string;
    message: string;
  }>;
};

export class StartTaskQueueElement extends TaskQueueElement {
  constructor(
    id: string,
    createdAt: LocalDate,
    public task: Task,
    public operationStatus: {
      isSavedToServer: boolean;
      isStartedToServer: boolean;
    },
    public errors: Array<{
      parameter: string;
      reason: string;
      message: string;
    }>
  ) {
    super(id, createdAt, 'start');
    this.label = this.task.subject;
    this.publicId = this.task.id;
  }

  static fromPayload(payload: StartTaskQueueElementPrimitives) {
    return new this(
      payload.id,
      LocalDate.fromIsoString(payload.created_at),
      Task.fromPrimitives(payload.task),
      payload.operation_status,
      payload.errors
    );
  }

  static fromTask(
    task: Task,
    operationStatus: { isSavedToServer: boolean; isStartedToServer: boolean },
    errors: Array<{
      parameter: string;
      reason: string;
      message: string;
    }>
  ) {
    return new this(UuidGenerator.random(), LocalDate.now(), task, operationStatus, errors);
  }

  toPrimitives(): StartTaskQueueElementPrimitives {
    return {
      id: this.id,
      type: this.type,
      created_at: this.createdAt.atom,
      task: this.task.toPrimitives(),
      operation_status: this.operationStatus,
      errors: this.errors,
    };
  }
}
