import { Injectable } from '@angular/core';
import { NFC } from '@awesome-cordova-plugins/nfc/ngx';
import { Platform } from '@ionic/angular';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { NdefRecord, NfcTag } from '@awesome-cordova-plugins/nfc';
import { Nullable } from '../../common/domain/types/types';
import { Initializable } from '../../common/domain/initializer/initializable';
import { Diagnostic } from '@awesome-cordova-plugins/diagnostic/ngx';

export type NFCResponse = { data: string; serialNumber: string };

@Injectable({
  providedIn: 'root',
})
export class NFCService implements Initializable {
  private isEnabled = false;
  private nfcDataSubject: Subject<NFCResponse> = new Subject<NFCResponse>();
  private readySubject = new BehaviorSubject<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/member-ordering
  ready$: Observable<boolean> = this.readySubject.asObservable();
  private nfcSubscription: Subscription;

  constructor(private platform: Platform, private nfc: NFC, private diagnostic: Diagnostic) {
    this.init().then();
  }

  listen(): Observable<NFCResponse> {
    if (!this.platform.is('cordova')) {
      return new Observable();
    }

    const flags = this.getReaderFlags();

    // Read NFC Tag - Android
    // Once the reader mode is enabled, any tags that are scanned are sent to the subscriber
    this.nfc.readerMode(flags).subscribe({
      next: (tag: NfcTag) => {
        const hexSerialNumber = this.getHexadecimalSerialNumber(tag);
        let encodedString = '';
        if (tag && tag.ndefMessage) {
          encodedString = this.decodeNdefMessage(tag.ndefMessage);
        }
        this.nfcDataSubject.next({ data: encodedString, serialNumber: hexSerialNumber });
      },
      error: (error) => {
        this.nfcDataSubject.error(error);
      },
    });

    return this.nfcDataSubject.asObservable();
  }

  private registerNFCStateChange() {
    this.diagnostic.registerNFCStateChangeHandler((state: string) => {
      switch (state) {
        case this.diagnostic.NFCState.UNKNOWN:
        case this.diagnostic.NFCState.POWERED_OFF:
          this.disable();
          break;
        case this.diagnostic.NFCState.POWERED_ON:
          this.enable();
          break;
        case this.diagnostic.NFCState.POWERING_OFF:
        case this.diagnostic.NFCState.POWERING_ON:
          break;
      }
    });
  }

  private getHexadecimalSerialNumber(tag: NfcTag) {
    return tag.id
      .map((char: number) => this.decimalToHexLittleEndian(char))
      .join(':')
      .toUpperCase();
  }

  private decimalToHexLittleEndian(decimalNumber: number): string {
    // eslint-disable-next-line no-bitwise
    return (decimalNumber & 0xff).toString(16).padStart(2, '0');
  }

  private getReaderFlags(): number {
    // eslint-disable-next-line no-bitwise
    return this.nfc.FLAG_READER_NFC_A | this.nfc.FLAG_READER_NFC_V;
  }

  private async init() {
    await this.platform.ready();
    if (!this.platform.is('cordova')) {
      return;
    }

    this.registerNFCStateChange();

    // first time
    const isAvailable = await this.diagnostic.isNFCAvailable();
    if (isAvailable) {
      this.enable();
    } else {
      this.disable();
    }
  }

  private enable() {
    this.isEnabled = true;
    this.readySubject.next(this.isEnabled);
  }

  private disable() {
    this.isEnabled = false;
    this.readySubject.next(this.isEnabled);
  }

  private decodeNdefMessage(ndefMessage: NdefRecord[]): Nullable<string> {
    const storedStrings = ndefMessage.map((n: NdefRecord) =>
      // convert bytes[] to string and remove all Unicode chars (like \u0002en: (STX, Start of Text))
      this.nfc.bytesToString(n.payload).replace('\u0002en', '')
    );
    return storedStrings.length > 0 ? storedStrings[0] : null;
  }

  private unsubscribeFromNFC() {
    if (this.nfcSubscription) {
      this.nfcSubscription.unsubscribe();
    }
  }
}
