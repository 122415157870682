import { Injectable } from '@angular/core';
import { LoadingController, LoadingOptions } from '@ionic/angular';
import { Nullable } from 'src/core/common/domain/types/types';

@Injectable({
  providedIn: 'root',
})
export class LoadingHelper {
  private isLoading = false;
  private loading: Nullable<HTMLIonLoadingElement> = null;

  constructor(private loadingController: LoadingController) {}

  public async end(): Promise<void> {
    if (!this.loading) {
      return;
    }
    await this.loading.dismiss();
    this.loading = null;
    this.isLoading = false;
  }

  public async start(message?: string): Promise<void> {
    if (this.isLoading) {
      return;
    }
    this.isLoading = true;

    let options: LoadingOptions = {
      spinner: 'circles',
      translucent: true,
    };

    if (message) {
      options = {
        message,
        spinner: 'circles',
        translucent: true,
        cssClass: 'rs-loading-with-message',
      };
    }
    this.loading = await this.loadingController.create(options);
    await this.loading.present();
  }
}
