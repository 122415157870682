import { Task } from 'src/core/master-data/domain/task';
import { RejectTaskQueueElement } from '../../../queue/domain/elements/reject-task-queue-element';

export class RejectTaskRequest {
  constructor(public readonly task: Task, public readonly queueElement: RejectTaskQueueElement) {}

  static fromTask(task: Task) {
    return new this(task, null);
  }

  static retry(element: RejectTaskQueueElement) {
    return new this(element.task, element);
  }
}
