export type MasterDataConfigurationData = {
  display_service_address_in_task: boolean;
  display_postal_address_in_immediate_task: boolean;
  display_postal_address_in_planned_task: boolean;
  display_ambit_in_immediate_task: boolean;
  display_ambit_in_planned_task: boolean;
  max_attachments: number;
  max_attachment_size: number;
  max_request_size: number;
  can_create_tasks: boolean;
  create_task_work_types: Array<{
    id: string;
    name: string;
  }>;
};

export class MasterDataConfiguration {
  constructor(public readonly value: MasterDataConfigurationData) {}

  isDisplayServiceAddressInTask(): boolean {
    return this.value.display_service_address_in_task;
  }

  isDisplayPostalAddressInImmediateTask(): boolean {
    return this.value.display_postal_address_in_immediate_task;
  }

  isDisplayPostalAddressInPlannedTask(): boolean {
    return this.value.display_postal_address_in_planned_task;
  }

  isDisplayAmbitInImmediateTask(): boolean {
    return this.value.display_ambit_in_immediate_task;
  }

  isDisplayAmbitInPlannedTask(): boolean {
    return this.value.display_ambit_in_planned_task;
  }

  getMaxAttachments(): number {
    return this.value.max_attachments;
  }

  getMaxAttachmentSize(): number {
    return this.value.max_attachment_size;
  }

  getMaxRequestSize(): number {
    return this.value.max_request_size;
  }

  getCreateTaskWorkTypes(): Array<{ id: string; name: string }> {
    return this.value.create_task_work_types;
  }

  canCreateTasks(): boolean {
    return this.value.can_create_tasks;
  }
}
