import { Translation } from 'src/core/common/domain/translation/translation';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AngularTranslation implements Translation {
  constructor(private readonly translateService: TranslateService) {}

  instant(key: string, params?: object): string {
    return this.translateService.instant(key, params);
  }

  async translate(key: string, params?: object): Promise<string> {
    return lastValueFrom(this.translateService.get(key, params));
  }

  locale(): string {
    return this.translateService.currentLang || this.translateService.defaultLang;
  }
}
