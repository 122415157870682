import { Task } from 'src/core/master-data/domain/task';

export class StartTaskRequest {
  constructor(
    public readonly task: Task,
    public readonly isRetry: boolean,
    public readonly isSavedDone: boolean,
    public readonly isStartDone: boolean
  ) {}

  static fromTask(task: Task) {
    return new this(task, false, false, false);
  }

  static retry(task: Task, isSaveDone: boolean, isStartDone: boolean) {
    return new this(task, true, isSaveDone, isStartDone);
  }
}
