import { ClientException, ClientExceptionConstructor } from './client-exception';

export class GenericClientException extends ClientException implements ClientExceptionConstructor {
  constructor(message?: string, error?: Error) {
    super(message, error);
    this.name = 'GenericClientException';
  }

  static fromError(error: Error): GenericClientException {
    return new this(error.message, error);
  }
}
