import { Nullable } from 'src/core/common/domain/types/types';

export abstract class Storage {
  abstract init(): Promise<void>;

  abstract reset(): Promise<void>;

  abstract get(key: string): Promise<Nullable<any>>;

  abstract remove(key: string): Promise<void>;

  abstract set(key: string, value: any): Promise<void>;

  abstract ready(): Promise<void>;
}
