import { Nullable } from 'src/core/common/domain/types/types';

export class OriginInfo {
  constructor(public readonly code: string, public readonly name: string) {}

  static from(data: Nullable<any>) {
    if (data === null) {
      return null;
    }
    return new this(data.code, data.name);
  }

  toPrimitives() {
    return {
      code: this.code,
      name: this.name,
    };
  }
}
