import { Nullable } from '../types/types';
import { ApiTaskLocationResponse } from '../../../master-data/infrastructure/api-task-location-response';

export class GeolocationHelper {
  static parseFromAPI(location: ApiTaskLocationResponse): Nullable<{ latitude: number; longitude: number }> {
    if (!location.geolocation || !location.geolocation.latitude || !location.geolocation.longitude) {
      return null;
    }

    const longitude = parseFloat(location.geolocation.longitude);
    const latitude = parseFloat(location.geolocation.latitude);
    if (isNaN(longitude) || isNaN(latitude) || longitude === 0 || latitude === 0) {
      return null;
    }

    return { longitude, latitude };
  }
}
