export class UnitHelper {
  static humanReadable(bytes: number): string {
    const units = ['bytes', 'KB', 'MB', 'GB'];
    let unitPosition = 0;

    while (bytes >= 1000 && unitPosition < units.length - 1) {
      // https://www.simonrjones.net/2022/01/a-little-bit-about-filesize-units/
      bytes = bytes / 1000;
      unitPosition++;
    }

    const numDecimals = unitPosition <= 1 ? 0 : 1;
    return bytes.toFixed(numDecimals) + ' ' + units[unitPosition];
  }
}
