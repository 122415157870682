import { Injectable } from '@angular/core';
import { HttpClient } from '../http/http-client';
import { ApiService } from '../../../api/domain/api.service';
import { ExceptionManagerService } from '../exceptions/exception-manager.service';
import { AccessToken } from '../../../authentication/domain/access-token';
import { ApiAccessResponse } from '../../../authentication/infrastructure/api-access-response';
import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { InvalidCredentialsException } from '../../../authentication/domain/exceptions/invalid-credentials-exception';
import { SessionService } from '../../../session/domain/session.service';
import { UnauthorizedException } from '../../../authentication/domain/exceptions/unauthorized-exception';

@Injectable({
  providedIn: 'any',
})
export class ApiAccessService {
  constructor(
    private readonly client: HttpClient,
    private readonly apiService: ApiService,
    private readonly session: SessionService,
    private readonly exceptionManager: ExceptionManagerService
  ) {}

  async getAccess(): Promise<void> {
    const headers = {
      Authorization: `Basic ${this.apiService.getBasicAuthorizationToken()}`,
    };
    const grantType = 'password'; // hardcoded
    const password = this.apiService.getPasswordToken();
    const username = this.apiService.getUsernameToken();

    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.client.post<ApiAccessResponse>(
          `${this.apiService.baseAccessUrl()}?grant_type=${grantType}&username=${username}&password=${password}`,
          null,
          headers,
          false,
          false
        );
        const accessToken = new AccessToken(response.access_token);
        await this.session.saveAccessToken(accessToken);
        resolve();
      } catch (exception) {
        // manage specific exceptions here
        if (exception instanceof HttpErrorResponse) {
          switch (exception.status) {
            case HttpStatusCode.Unauthorized:
              reject(InvalidCredentialsException.fromError(exception));
          }
        }

        // manage common exceptions here
        reject(this.exceptionManager.manage(exception));
      }
    });
  }

  async renewApiAccessIfNeeded() {
    const accessToken = this.session.getAccessToken();
    if (accessToken === null) {
      throw new UnauthorizedException();
    }

    if (!accessToken.isExpired()) {
      return;
    }

    await this.getAccess();
    console.warn(' - API access token renewed after expiration');
  }
}
