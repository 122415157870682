import { Injectable } from '@angular/core';
import { Storage } from '../core/storage/domain/storage';
import { Nullable } from '../core/common/domain/types/types';
import { LocalDate } from '../core/common/domain/date/local-date';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  private readonly KEY_GO_TO_BACKGROUND_TIME = 'app.in_pause';

  constructor(private readonly storage: Storage) {}

  async recordAppGoingToBackground(): Promise<void> {
    await this.storage.set(this.KEY_GO_TO_BACKGROUND_TIME, LocalDate.now().timestamp);
  }

  async getAppPutToBackgroundTime(): Promise<Nullable<LocalDate>> {
    const timestamp = await this.storage.get(this.KEY_GO_TO_BACKGROUND_TIME);

    return timestamp ? LocalDate.fromTimestamp(+timestamp) : null;
  }
}
