import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Translation } from 'src/core/common/domain/translation/translation';

@Injectable({
  providedIn: 'root',
})
export class MessageHelper {
  constructor(
    private readonly alertController: AlertController,
    private readonly toastCtrl: ToastController,
    private readonly translation: Translation
  ) {}

  async showSuccess(message: string, duration: number = 1000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      color: 'success',
      duration,
      cssClass: 'hack-toast-tap-to-dismiss',
    });
    await toast.present();
  }

  async showWarning(message: string, duration: number = 2000): Promise<void> {
    const toast = await this.toastCtrl.create({
      message,
      color: 'warning',
      duration,
      cssClass: 'hack-toast-tap-to-dismiss',
    });
    await toast.present();
  }

  async showInfo(message: string, duration: number = 2000, showCloseButton: boolean = false): Promise<void> {
    const buttons = [];
    const ok = await this.translation.translate('COMMON.OK');
    buttons.push({
      text: ok,
      role: 'cancel',
      handler: () => {},
    });
    if (showCloseButton) {
      // In this case we should not use our hack tap to dismiss since there already is a button
      const toast = await this.toastCtrl.create({
        message,
        duration,
        color: 'medium',
        buttons,
      });
      await toast.present();
    } else {
      // If no close button, apply the toast tap to dismiss hack
      const toast = await this.toastCtrl.create({
        message,
        duration,
        color: 'medium',
        buttons,
        cssClass: 'hack-toast-tap-to-dismiss',
      });
      await toast.present();
    }
  }

  async showError(message: string): Promise<void> {
    const ok = await this.translation.translate('COMMON.OK');
    const toast = await this.toastCtrl.create({
      message,
      color: 'danger',
      buttons: [
        {
          text: ok,
          role: 'cancel',
          handler: () => {},
        },
      ],
    });
    await toast.present();
  }

  async showAlertError(header: string, message: string): Promise<void> {
    const alert = await this.alertController.create({
      header,
      message,
      buttons: ['OK'],
    });
    await alert.present();
  }

  async askConfirmation(header: string, message: string, okButtonText: string = null): Promise<boolean> {
    const ok = okButtonText ?? this.translation.instant('COMMON.OK');
    const cancel = this.translation.instant('COMMON.CANCEL');

    return new Promise(async (resolve) => {
      const alert = await this.alertController.create({
        header,
        message,
        buttons: [
          {
            text: cancel,
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
          {
            text: ok,
            handler: () => {
              resolve(true);
            },
          },
        ],
      });
      await alert.present();
    });
  }

  async showNeedsConnectionInfo() {
    await this.showWarning(this.translation.instant('MESSAGES.NEEDS_CONNECTION'));
  }

  async askConfirmationAboutLosingPendingChanges(): Promise<boolean> {
    const okButtonText = this.translation.instant('COMMON.CONTINUE_BTN');
    const header = this.translation.instant('LOGOUT.PENDING_CHANGES_TITLE');
    const message = this.translation.instant('LOGOUT.PENDING_CHANGES_MESSAGE');

    return await this.askConfirmation(header, message, okButtonText);
  }
}
