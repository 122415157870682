export type CredentialsMessage = { username: string | null; password: string | null; serialNumber: string | null };
export type LocationMessage = { locationCode: string };

export class NFCMessageParser {
  static isCredentialsMessage(data: string): boolean {
    return data.startsWith('credentials|##|') || data.trim() === '';
  }

  static processCredentialsMessage(data: string, serialNumber: string): CredentialsMessage {
    if (data.trim() === '') {
      return { username: null, password: null, serialNumber };
    }

    if (!data.startsWith('credentials|##|')) {
      throw new Error('nfc_code_not_recognized');
    }

    const parts = data.split('|##|');
    const username = parts[1];
    const password = parts[2];

    return { username, password, serialNumber: null };
  }

  static processLocationMessage(data: string): LocationMessage {
    if (data.startsWith('credentials|##|')) {
      throw new Error('credentials_cant_be_used_here');
    }

    return { locationCode: data };
  }
}
