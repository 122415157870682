import { v4 } from 'uuid';
import { IdGenerator } from 'src/core/common/domain/uuid/id-generator';

export class UuidGenerator extends IdGenerator {
  static random(): string {
    return v4();
  }

  generate(): string {
    return v4();
  }
}
